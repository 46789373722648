import * as React from 'react'
import { Route } from 'react-router'
import Layout from './components/Layout'
import Home from './components/Home'
import Counter from './components/Counter'
import LoginPage from './pages/LoginPage'
import PrivateRoute from './components/PrivateRoute'
import { urls } from './urls'
import InventoryPage from './pages/InventoryPage'
import LanguagesPage from './pages/LanguagesPage'
import CurrenciesPage from './pages/CurrenciesPage'
import CountriesPage from './pages/CountriesPage'
import CountryFormPage from './pages/CountryFormPage'
import CurrencyFormPage from './pages/CurrencyFormPage'
import CountryTranslationFormPage from './pages/CountryTranslationFormPage'
import CityFormPage from './pages/CityFormPage'
import CityTranslationFormPage from './pages/CityTranslationFormPage'
import TourCategoryFormPage from './pages/TourCategoryFormPage'
import ToursCategoriesPage from './pages/ToursCategoriesPage'
import HotelsPage from './pages/HotelsPage'
import HotelFormPage from './pages/HotelFormPage'
import PromoCodes from './pages/Marketing/PromoCodesPage'
import PromoCodeFormPage from './pages/Marketing/PromoCodeFormPage'

export default () => (
    <Layout>
        <Route exact path={urls.login} component={LoginPage} />
        <PrivateRoute exact path="/" component={Home} />
        <PrivateRoute exact path={urls.inventory} component={InventoryPage} />
        <PrivateRoute exact path={urls.languages} component={LanguagesPage} />
        <PrivateRoute exact path={urls.countries} component={CountriesPage} />
        <PrivateRoute exact path={urls.toursCategories} component={ToursCategoriesPage} />
        <PrivateRoute exact path={urls.currencies} component={CurrenciesPage} />
        <PrivateRoute exact path={`${urls.currencies}/:id`} component={CurrencyFormPage} />
        <PrivateRoute path={`${urls.cities}/:countryId/:cityId`} component={CityFormPage} />
        <PrivateRoute
            path={`${urls.cities}/:countryId/:cityId/details/:language`}
            component={CityTranslationFormPage}
        />
        <PrivateRoute path={`${urls.toursCategories}/:tourCategoryId`} component={TourCategoryFormPage} />

        <PrivateRoute path={`${urls.countries}/:id`} component={CountryFormPage} />
        <PrivateRoute path={`${urls.countries}/:id/details/:language`} component={CountryTranslationFormPage} />
        <PrivateRoute path={`${urls.hotels}/:countryId?`} component={HotelsPage} />
        <PrivateRoute roles={["admin", "hotelsadmin"]} path={`${urls.hotels}/:countryId/:id`} component={HotelFormPage} />
        <PrivateRoute exact path={`${urls.promoCodes}`} component={PromoCodes} />
        <PrivateRoute exact path={`${urls.promoCodes}/:id`} component={PromoCodeFormPage} />
        <PrivateRoute path="/counter" component={Counter} />
    </Layout>
)
