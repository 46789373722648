import * as React from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { Col, Grid, IdFormField, Popup, PopupFormWrapper, Row, TextAreaField, TextFormField } from 'traveldesk-ui'
import { urls } from '../urls'
import { ApplicationState } from '../store'
import { MetaDataModelCity, MetaDataModelTourCategory } from '../models/MetaDataModel'
import { nameof } from 'traveldesk-ui'
import { Buttons } from 'traveldesk-ui'
import { actionCreators as citiesActions } from '../store/Cities'
import { REPL_MODE_SLOPPY } from 'repl'

interface Props {
    tourCategoryTranslation: MetaDataModelTourCategory
    onClose: () => void
    onSave: (tcT: MetaDataModelTourCategory) => void
}
const TourCategoryTranslationFormPopup = (props: Props) => {
    const tcT = props.tourCategoryTranslation
    const dispatch = useDispatch()
    const languages = useSelector((state: ApplicationState) => state.languages.data)
    const language = React.useMemo(() => {
        return languages.find((x) => x.id == tcT.languageId)
    }, [languages, props.tourCategoryTranslation])
    const Localization = useSelector((state: ApplicationState) => state.localization.locale)
    const { handleSubmit, formState: { errors }, control, register, getValues, setValue, watch, reset, setError } = useForm()
    const onSubmit = (data: any) => {
        const model = Object.assign(new MetaDataModelCity(), data, { tourCategoryId: tcT.tourCategoryId })
        props.onSave(model)
    }
    React.useEffect(() => {
        reset(props.tourCategoryTranslation)
    }, [props.tourCategoryTranslation, language])
    return language ? (
        <Popup onClose={props.onClose}>
            <PopupFormWrapper>
                <Grid>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col type="100">
                                <h3>{`${Localization.getString('Manage')} ${tcT.name} ${Localization.getString(
                                    'meta data in',
                                )} ${language.name}`}</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col type="50">
                                <IdFormField name="languageId" control={control} />
                                <TextFormField
                                    label={Localization.getString('Tour category name')}
                                    placeholder={Localization.getString('Family Packages')}
                                    subLabel={`${Localization.getString('Tour category name in')} ${language.name}`}
                                    isRequired
                                    error={errors.name ? errors.name.message : ''}
                                    // defaultValue={tour.nameInternal}
                                    name={nameof<MetaDataModelTourCategory>('name')}
                                    register={register}
                                    options={{
                                        required: Localization.getString('Tour category name is required'),
                                    }}
                                />
                                <TextFormField
                                    label={Localization.getString('Page title')}
                                    // placeholder={Localization.getString('United Arab Emirates')}
                                    subLabel={`${Localization.getString('Tour category page title in')} ${language.name
                                        }`}
                                    isRequired
                                    error={errors.pageTitle ? errors.pageTitle.message : ''}
                                    // defaultValue={tour.nameInternal}
                                    name={nameof<MetaDataModelTourCategory>('pageTitle')}
                                    register={register}
                                    options={{
                                        required: Localization.getString('Page title is required'),
                                    }}
                                />
                            </Col>
                            <Col type="50">
                                <TextAreaField
                                    label={Localization.getString('Short Description')}
                                    subLabel={`${Localization.getString('Tour category short description in')} ${language.name
                                        }`}
                                    name={nameof<MetaDataModelTourCategory>('shortDescription')}
                                    register={register}

                                />
                                <TextAreaField
                                    label={Localization.getString('Description')}
                                    subLabel={`${Localization.getString('Tour category long description in')} ${language.name
                                        }`}
                                    name={nameof<MetaDataModelTourCategory>('description')}
                                    register={register}

                                />
                            </Col>
                        </Row>
                        <Buttons.StickySaveButton>{Localization.getString('Save')}</Buttons.StickySaveButton>
                    </form>
                </Grid>
            </PopupFormWrapper>
        </Popup>
    ) : null
}
export default TourCategoryTranslationFormPopup
