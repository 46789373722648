import { EntitiesPaginatedModel, PaginationModel, apiCallWrapper } from "traveldesk-ui";
import HotelsFilter from "../viewModels/HotelsFilter";
import HotelModel from "../models/HotelModel";

export default class HotelsApiService {
    public static async getHotels(filter: HotelsFilter, dispatch: any) {
        let result = await apiCallWrapper<EntitiesPaginatedModel<HotelModel>>('/api/hotels', 'POST', dispatch, filter)
        if (result.Content) {
            result.Content.pagingInfo = PaginationModel.Create(result.Content.pagingInfo)
        }
        return result
    }
    public static async getHotel(id: number, dispatch: any) {
        return await apiCallWrapper<HotelModel>(`/api/hotels/${id}`, "GET", dispatch)
    }
    public static async save(hotel: HotelModel, dispatch: any) {
        return await apiCallWrapper<HotelModel>(`/api/hotels`, 'PUT', dispatch, hotel)
    }
}