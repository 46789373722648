import { apiCallWrapper } from 'traveldesk-ui'
import InventoryModel from '../models/InventoryModel'
import { EntitiesPaginatedModel, PaginationModel } from 'traveldesk-ui'
import InventoryFilter from './../viewModels/InventoryFilter'
export default class InventoryApiService {
    public static async update(model: InventoryModel, dispatch: any) {
        const data = await apiCallWrapper<InventoryModel>(`/api/inventory`, 'PUT', dispatch, model)
        if (data.Content) {
            data.Content = InventoryModel.Create(data.Content)
        }
        return data
    }
    public static async getList(filter: InventoryFilter, dispatch: any) {
        const data = await apiCallWrapper<EntitiesPaginatedModel<InventoryModel>>(
            `/api/inventory/filter`,
            'POST',
            dispatch,
            filter,
        )
        if (data.Content) {
            const content = Object.assign(new EntitiesPaginatedModel<InventoryModel>(), data.Content, {
                entities: data.Content.entities.map((e) => Object.assign(InventoryModel.Create(e))),
                pagingInfo: PaginationModel.Create(data.Content.pagingInfo),
            })
            data.Content = content
        }
        return data
    }
}
