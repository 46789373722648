import * as React from 'react'
import { Redirect, RouteComponentProps } from 'react-router'
import { actionCreators as countriesActions } from '../store/Countries'
import { actionCreators as languagesActions } from '../store/Languages'
import { actionCreators as currenciesActions } from '../store/Currencies'

import {
    Grid,
    Row,
    Col,
    SectionTitle,
    ContainerWithPadding,
    IdFormField,
    TextFormField,
    Tooltip,
    SelectFormFieldController,
    Buttons,
    Photo,
    ToggleButtonsFormField,
    ToggleFieldController,
} from 'traveldesk-ui'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../store'
import CountryModel from '../models/CountryModel'
import { urls } from '../urls'
import { LoadingSection } from '../components/LoadingSection'
import { DataTable, DataTableColumnModel, DataTableModel, DataTableModelCustomAction, FilterType } from 'traveldesk-ui'
import { CityModel } from '../models/CityModel'
import { TourCategoryModel } from '../models/TourCategoryModel'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { nameof } from 'traveldesk-ui'
import { MetaDataModelCountry } from '../models/MetaDataModel'
import { MediaSelector } from 'traveldesk-ui'

interface Props extends RouteComponentProps<{ id: string }> { }
const CountryFormPage = (props: Props) => {
    const dispatch = useDispatch()
    const { register, handleSubmit, formState: { errors }, control, getValues, setValue, watch, reset, setError } = useForm()
    const Localization = useSelector((state: ApplicationState) => state.localization.locale)
    const languages = useSelector((state: ApplicationState) => state.languages.data)
    const currenciesOptions = useSelector((state: ApplicationState) => state.currencies.options)
    const countries = useSelector((state: ApplicationState) => state.countries.manageList)

    React.useEffect(() => {
        const id = parseInt(props.match.params.id)
        if (countries.length > 0) {
            const c = countries.find((x) => x.id == id) || new CountryModel()
            if (c.id != id) {
                c.id = -1
            }
            dispatch(countriesActions.editCountry(c))
        }
    }, [countries, props.match.params.id])
    React.useEffect(() => {
        return () => {
            dispatch(countriesActions.editCountry(undefined))
        }
    }, [])
    const country = useSelector((state: ApplicationState) => state.countries.edit)
    const [heroImageId, setHeroImageId] = React.useState(country?.heroImageId)
    const onHeroImageChanged = (photo: Photo) => {
        setHeroImageId(photo.id)
    }
    const [photos, setPhotos] = React.useState(country?.photos ?? [])
    const onSelectPhotos = (newPhotos: Photo[]) => {
        setPhotos(photos.concat(newPhotos))
    }
    React.useEffect(() => {
        if (country && country.id > 0 && country.cities.length == 0) {
            dispatch(countriesActions.requestCountryCities(country.id))
        }
    }, [country])
    React.useEffect(() => {
        if (countries.length == 0) {
            dispatch(countriesActions.requestManageList())
        }
        if (languages.length == 0) {
            dispatch(languagesActions.requestData())
        }
        if (currenciesOptions.length == 0) {
            dispatch(currenciesActions.requestData())
        }
    }, [])
    const [showAllLanguages, setShowAllLanguages] = React.useState(false)

    const initTranslationsTable = () => {
        const columns = [
            new DataTableColumnModel({
                fieldName: 'languageName',
                displayName: Localization.getString('Language'),
                sortable: true,
                filterType: FilterType.TEXT,
            }),
            new DataTableColumnModel({
                fieldName: 'name',
                displayName: Localization.getString('Country Name'),
                sortable: true,
                filterType: FilterType.TEXT,
            }),
            new DataTableColumnModel({
                fieldName: 'pageTitle',
                displayName: Localization.getString('Page Title'),
                // sortable: true,
                filterType: FilterType.TEXT,
            }),
            new DataTableColumnModel({
                fieldName: 'shortDescription',
                displayName: Localization.getString('Short Description'),
                filterType: FilterType.TEXT,
                customRenderer: (obj: MetaDataModelCountry) => (
                    <Tooltip text={obj.shortDescription}>
                        {(obj.shortDescription && obj.shortDescription.length) > 100
                            ? obj.shortDescription.substring(0, 100) + '...'
                            : obj.shortDescription || ''}
                    </Tooltip>
                ),
            }),
            new DataTableColumnModel({
                fieldName: 'description',
                displayName: Localization.getString('Description'),
                filterType: FilterType.TEXT,
                customRenderer: (obj: MetaDataModelCountry) => (
                    <Tooltip text={obj.description}>
                        {(obj.description && obj.description.length) > 100
                            ? obj.description.substring(0, 100) + '...'
                            : obj.description || ''}
                    </Tooltip>
                ),
            }),
            new DataTableColumnModel({
                fieldName: '',
                displayName: '',
                customRenderer: (obj: MetaDataModelCountry) => (
                    <Link to={urls.countryTranslationForm(country?.id || 0, obj.languageId)}>
                        <i className="fa fa-pencil" />
                    </Link>
                ),
            }),
        ]
        return new DataTableModel({
            name: Localization.getString('Translations'),
            columns,
            customActions: [
                new DataTableModelCustomAction(
                    () => { },
                    () => (
                        <Tooltip
                            style={{ width: 200 }}
                            text={
                                showAllLanguages
                                    ? Localization.getString('Show all languages')
                                    : Localization.getString('Show only languages with tours')
                            }
                        >
                            <i
                                onClick={() => setShowAllLanguages(!showAllLanguages)}
                                className={`fa fa-eye ${showAllLanguages ? 'active' : ''}`}
                            />
                        </Tooltip>
                    ),
                ),
            ],
        })
    }
    const initCitiesTable = () => {
        const columns = [
            new DataTableColumnModel({
                fieldName: 'name',
                displayName: Localization.getString('City Name'),
                sortable: true,
                filterType: FilterType.TEXT,
            }),
            new DataTableColumnModel({
                fieldName: 'url',
                displayName: Localization.getString('Slug'),
                sortable: true,
                filterType: FilterType.TEXT,
            }),
            new DataTableColumnModel({
                fieldName: 'isTopCity',
                displayName: 'Is Top City',
                customRenderer: (obj: CityModel) => (obj.isTopCity ? <i className="fa fa-check" /> : null),
            }),
            new DataTableColumnModel({
                fieldName: '',
                width: 35,
                displayName: '',
                customRenderer: (obj: CityModel) => (
                    <Link to={`${urls.cityForm(country?.id ?? 0, obj.id)}`}>
                        <i className="fa fa-pencil" />
                    </Link>
                ),
            }),
        ]
        return new DataTableModel({
            name: Localization.getString('Cities'),
            columns,

            customActions: [
                new DataTableModelCustomAction(
                    () => { },
                    () =>
                        country ? (
                            <Link to={urls.cityForm(country?.id, 0)}>
                                <i className="fa fa-plus" />
                            </Link>
                        ) : null,
                ),
            ],
        })
    }

    const translationsData = React.useMemo(() => {
        const l = showAllLanguages ? languages : languages.filter((x) => x.isOffers)
        if (country) {
            const res = l.map((x) =>
                Object.assign(
                    country?.metaData.find((m) => m.languageId == x.id) ??
                    MetaDataModelCountry.FromLanguage(x, country.id),
                    {
                        languageName: x.name,
                    },
                ),
            )
            return res
        }
        return []
    }, [languages, country, showAllLanguages])
    React.useEffect(() => {
        setPhotos(country?.photos ?? [])
        setHeroImageId(country?.heroImageId)
        reset(country)
    }, [country?.id || -1])
    const onSubmit = (data: any) => {
        if (country) {
            const model = Object.assign(CountryModel.Create(data), { metaData: country.metaData, heroImageId, photos })
            model.cities = []
            dispatch(countriesActions.saveData(model))
        }
    }
    const dataTableCitiesModel = React.useMemo(() => initCitiesTable(), [Localization, country])
    const dataTableTranslationsModel = React.useMemo(() => initTranslationsTable(), [
        showAllLanguages,
        Localization,
        country,
    ])
    return (
        <ContainerWithPadding>
            {country ? (
                country.id == -1 ? (
                    <Redirect to={urls.countries} />
                ) : (
                    <Grid>
                        <SectionTitle
                            title={
                                country.id > 0
                                    ? `${Localization.getString('Edit Country')} ${country.name}`
                                    : Localization.getString('Add Country')
                            }
                        />
                        <Link className="a-back-to-list" to={urls.countries}>
                            {Localization.getString('Back to countries list')}
                        </Link>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                                <Col type="50">
                                    <IdFormField control={control} />
                                    <TextFormField
                                        label={Localization.getString('Country Name')}
                                        placeholder={Localization.getString('Name of the country')}
                                        // subLabel={Localization.getString('Name of the country')}
                                        isRequired
                                        error={errors.name ? errors.name.message : ''}
                                        // defaultValue={tour.nameInternal}
                                        name={nameof<CountryModel>('name')}
                                        register={register}
                                        options={{
                                            required: Localization.getString('Country name is required'),
                                        }}
                                    />
                                    <TextFormField
                                        label={Localization.getString('Slug')}
                                        placeholder={Localization.getString('Url of the country')}
                                        // subLabel={Localization.getString('Internal name of your tour')}
                                        isRequired
                                        error={errors.url ? errors.url.message : ''}
                                        // defaultValue={tour.nameInternal}
                                        name={nameof<CountryModel>('url')}
                                        register={register}
                                        options={{
                                            required: Localization.getString('Country slug is required'),
                                        }}
                                    />
                                    <ToggleFieldController
                                        control={control}
                                        label={Localization.getString('Top Country')}
                                        name={nameof<CountryModel>('isTopCountry')}
                                    />
                                </Col>

                                <Col type="50">
                                    <SelectFormFieldController
                                        control={control}
                                        name={nameof<CountryModel>('currencyId')}
                                        label={Localization.getString('Default Currency')}
                                        options={currenciesOptions}
                                    // isNotSearchable={true}
                                    />
                                    <MediaSelector
                                        heroImageId={heroImageId}
                                        onHeroImageChanged={onHeroImageChanged}
                                        selected={photos}
                                        onSelectPhotos={onSelectPhotos}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col type="100">
                                    <DataTable
                                        data={translationsData}
                                        fixedHeight={300}
                                        dataTable={dataTableTranslationsModel}
                                    />
                                </Col>
                            </Row>
                            <Buttons.StickySaveButton>{Localization.getString('Save')}</Buttons.StickySaveButton>
                            {country.id > 0 && (
                                <Row>
                                    <Col type="100">
                                        <DataTable
                                            sortField="name"
                                            sortType="ASC"
                                            fixedHeight={300}
                                            data={country.cities}
                                            dataTable={dataTableCitiesModel}
                                        />
                                    </Col>
                                </Row>
                            )}
                        </form>
                    </Grid>
                )
            ) : (
                <LoadingSection />
            )}
        </ContainerWithPadding>
    )
}
export default CountryFormPage
