import * as React from 'react'
import Header from '../components/Header'
import Loading from '../components/Loading'
import { Layout as LayoutWrapper } from 'traveldesk-ui'
import { useLocation } from 'react-router'
export default (props: { children?: React.ReactNode }) => {
    const { pathname } = useLocation()
    React.useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        })
    }, [pathname])
    return (
        <React.Fragment>
            <LayoutWrapper></LayoutWrapper>
            <Header />
            <Loading />
            {props.children}
        </React.Fragment>
    )
}
