import { apiCallWrapper } from 'traveldesk-ui'
import { AppThunkAction } from './'
import { Action, Reducer } from 'redux'
import TranslationText from '../models/TranslationText'

export interface State {
    data: TranslationText[]
    isLoading: boolean
}

const REQUEST_TRANSLATIONS = 'A_REQUEST_TRANSLATIONS'
interface RequestTranslations {
    type: 'A_REQUEST_TRANSLATIONS'
}
const RECEIVE_TRANSLATIONS = 'A_RECEIVE_TRANSLATIONS'
interface ReceiveTranslations {
    type: 'A_RECEIVE_TRANSLATIONS'
    payload: TranslationText[]
}

type KnownAction = ReceiveTranslations | RequestTranslations

export const actionCreators = {
    requestData: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState()
        if (
            appState &&
            appState.translations &&
            !appState.translations.isLoading &&
            appState.translations.data.length === 0
        ) {
            const result = await apiCallWrapper<TranslationText[]>('/api/translations', 'GET', dispatch)
            if (result.IsOk) {
                dispatch({ type: RECEIVE_TRANSLATIONS, payload: result.Content || [] })
            }
        }
    },
}

const unloadedState: State = { data: [] as TranslationText[], isLoading: false }
export const reducer: Reducer<State> = (state: State | undefined, incomingAction: Action): State => {
    if (state === undefined) {
        return unloadedState
    }

    const action = incomingAction as KnownAction
    switch (action.type) {
        case REQUEST_TRANSLATIONS:
            return {
                ...state,
                isLoading: true,
            }
        case RECEIVE_TRANSLATIONS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
            }
        default:
            return state
    }
}
