import { Action, Reducer } from 'redux'
import { AppThunkAction } from '.'
// import { Localization } from '../localization'
import { LoadingStore } from 'traveldesk-ui'

export interface State {
    city?: number
    path: string
}
const SET_GLOBAL_PATH = 'A_SET_GLOBAL_PATH'
interface SetGlobalPath {
    type: 'A_SET_GLOBAL_PATH'
    path: string
}

const SET_GLOBAL_CITY = 'A_SET_GLOBAL_CITY'
interface SetGlobalCityAction {
    type: 'A_SET_GLOBAL_CITY'
    city: number
}

type KnownAction = SetGlobalCityAction | SetGlobalPath

export const actionCreators = {
    initialize: (): AppThunkAction<KnownAction | LoadingStore.KnownAction> => async (dispatch, getState) => {
        // const language = getState().global.language
        // const locale = new Localization()
        // await locale.setLanguage(language, dispatch)
        // dispatch({ type: SET_GLOBAL_LANGUAGE, language, locale } as SetGlobalLanguageAction)
    },

    setPath: (path: string) => ({ type: SET_GLOBAL_PATH, path }),
    setCity: (city: number) => ({ type: SET_GLOBAL_CITY, city } as SetGlobalCityAction),
}
const storageState = localStorage ? localStorage.getItem('settings') : undefined
const unloadedState: State = storageState ? Object.assign(JSON.parse(storageState)) : { city: undefined }
const updateStorage = (state: State) => {
    if (localStorage) {
        localStorage.setItem('settings', JSON.stringify(state))
    }
}
export const reducer: Reducer<State> = (state: State | undefined, incomingAction: Action): State => {
    if (state === undefined) {
        return unloadedState
    }

    const action = incomingAction as KnownAction
    switch (action.type) {
        case SET_GLOBAL_PATH:
            return {
                ...state,
                path: action.path,
            }

        default:
            return state
    }
}
