import * as React from 'react'
import { Grid, Row, Col, SectionTitle, ContainerWithPadding } from 'traveldesk-ui'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../store/index'
import { LoadingSection } from './../components/LoadingSection'
import { DataTable, DataTableColumnModel, DataTableModel, DataTableModelCustomAction, FilterType } from 'traveldesk-ui'
import { actionCreators as countriesActions } from '../store/Countries'
import CountryModel from '../models/CountryModel'
import { Link } from 'react-router-dom'
import { urls } from '../urls'
const CountriesPage = () => {
    const countries = useSelector((state: ApplicationState) => state.countries.manageList)
    const Localization = useSelector((state: ApplicationState) => state.localization.locale)
    const dispatch = useDispatch()
    React.useEffect(() => {
        dispatch(countriesActions.requestManageList())
    }, [])
    const onRefresh = () => {
        dispatch(countriesActions.requestManageList())
    }
    const initCountriesTable = () => {
        const columns = [
            new DataTableColumnModel({
                fieldName: 'name',
                displayName: Localization.getString('Country Name'),
                sortable: true,
                filterType: FilterType.TEXT,
            }),
            new DataTableColumnModel({
                fieldName: 'url',
                displayName: Localization.getString('Slug'),
                filterType: FilterType.TEXT,
            }),
            new DataTableColumnModel({
                fieldName: '',
                displayName: '',
                width: 35,
                customRenderer: (obj: CountryModel) => (
                    <Link to={`/countries/${obj.id}`}>
                        <i className="fa fa-pencil" />
                    </Link>
                ),
            }),
            new DataTableColumnModel({
                fieldName: "isTopCountry",
                displayName: "Is Top Country",
                width: 35,
                customRenderer: (obj: CountryModel) => obj.isTopCountry ? <i className='fa fa-check' /> : null
            })
        ]
        return new DataTableModel({
            name: Localization.getString('Countries'),
            columns,
            customActions: [
                new DataTableModelCustomAction(
                    () => { },
                    () => (
                        <Link to={`${urls.countries}/0`}>
                            <i className="fa fa-plus" />
                        </Link>
                    ),
                ),
            ],
        })
    }
    return <DataTable
        isFullScreen
        sortType="ASC"
        sortField="name"
        data={countries}
        onRefreshData={onRefresh}
        dataTable={initCountriesTable()}
    />
}
export default CountriesPage
