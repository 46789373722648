import * as React from 'react'
import { RouteComponentProps } from 'react-router';
import { urls } from '../urls';
import { Buttons, Col, FormFieldWrapper, Grid, IdFormField, MapsLocation, MapsLocationPicker, Popup, PopupFormWrapper, Row, SelectFormField, SelectFormFieldController, SelectOptionModel, TextFormField, nameof } from 'traveldesk-ui';
import { ApplicationState } from '../store';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { actionCreators as hotelsActions } from '../store/Hotels'
import { actionCreators as citiesActions } from '../store/Cities'
import HotelModel from '../models/HotelModel';
import HotelsApiService from '../services/HotelsApiService';
import { slugify } from 'transliteration';
interface Props extends RouteComponentProps<{ id: string, countryId: string }> { }
const HotelFormPage = (props: Props) => {
    const dispatch = useDispatch()
    const countries = useSelector((state: ApplicationState) => state.countries)
    const cities = useSelector((state: ApplicationState) => state.cities)
    const Localization = useSelector((state: ApplicationState) => state.localization.locale)
    const { handleSubmit, formState: { errors }, control, register, getValues, setValue, watch, reset, setError } = useForm()
    const [hotel, setHotel] = React.useState<HotelModel>(new HotelModel())
    const onSubmit = (data: HotelModel) => {
        if (!data.cityId) {
            alert("Please select city")
            return
        }
        const city = cities.citiesInCountryWithRegionsOptions.get(data.countryId)?.find(x => x.value == data.cityId)
        if (!data.regionId && (city?.regions.length ?? 0) > 0) {
            alert("Please select region")
            return
        }
        if (!data.latitude || !data.longitude) {
            alert("Please select location on map using address autocomplete or placing a marker")
            return
        }
        dispatch(hotelsActions.save(data))
    }

    const getHotel = async (id: number) => {
        const hotel = await HotelsApiService.getHotel(id, dispatch)
        if (!hotel) {
            props.history.push(urls.hotels)
        }
        setLocation(new MapsLocation(hotel.Content?.address ?? "", hotel.Content?.latitude, hotel.Content?.longitude))
        if (hotel.Content) {
            setHotel(hotel.Content)
        }
        reset(hotel.Content)
    }
    React.useEffect(() => {
        const idStr = props.match.params.id
        if (idStr == 'add') {
            const countryIdStr = props.match.params.countryId
            let hotel = new HotelModel()
            if (countryIdStr) {
                const countryId = parseInt(countryIdStr)
                hotel = Object.assign(hotel, { countryId })
                onChangeCountry(countryId)
            }
            reset(hotel)
        }
        else {
            if (idStr) {
                const id = parseInt(idStr)
                getHotel(id)
            }
            else {
                props.history.push(urls.hotels)
            }
        }
    }, [props.match.params.id])
    const model = watch()
    const countryId = watch('countryId')
    const cityId = watch('cityId')
    const name = watch('name')
    React.useEffect(() => {
        setValue("nameUrl", slugify(name))
    }, [name])
    const onChangeCountry = async (countryId: number) => {
        if (!cities.citiesInCountryWithRegionsOptions.get(countryId)) {
            dispatch(citiesActions.requestCitiesOptionsInCountryWithRegions(countryId))
        }
    }
    const regionsOptions = React.useMemo(() => {
        return cities.citiesInCountryWithRegionsOptions.get(countryId)?.find(x => x.value == cityId)?.regions ?? []
    }, [cityId, cities])
    const [location, setLocation] = React.useState<MapsLocation>(new MapsLocation("", undefined, undefined))
    const onSetLocation = (location: MapsLocation) => {
        setValue('latitude', location.lat)
        setValue('longitude', location.lng)
        setValue('address', location.address)
        setLocation(location)
    }
    return <Popup onClose={() => props.history.push(urls.hotelsInCountry(parseInt(props.match.params.countryId)))}>
        <PopupFormWrapper>
            <Grid>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Col type="100">
                            <h3>{hotel.id > 0
                                ? `${Localization.getString('Edit hotel')} '${hotel.name}'`
                                : `${Localization.getString('Add hotel')}`
                            }</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col type="50">
                            <IdFormField name="id" control={control} />
                            <TextFormField
                                label={Localization.getString('Name')}
                                // placeholder={Localization.getString('United Arab Emirates')}
                                // subLabel={`${Localization.getString('Country name in')} ${language.name}`}
                                isRequired
                                error={errors.name ? errors.name.message : ''}
                                // defaultValue={tour.nameInternal}
                                name={nameof<HotelModel>('name')}
                                register={register}
                                options={{
                                    required: Localization.getString('Hotel name is required'),
                                }}
                            />
                        </Col>
                        <Col type="50">
                            <TextFormField
                                label={Localization.getString('Slug')}
                                // placeholder={Localization.getString('United Arab Emirates')}
                                // subLabel={`${Localization.getString('Country name in')} ${language.name}`}
                                isRequired
                                error={errors.nameUrl ? errors.nameUrl.message : ''}
                                // defaultValue={tour.nameInternal}
                                name={nameof<HotelModel>('nameUrl')}
                                register={register}
                                options={{
                                    required: Localization.getString('Hotel slug is required'),
                                }}
                            />

                        </Col>
                    </Row>
                    <Row>
                        <Col type="50">
                            <SelectFormFieldController
                                label={Localization.getString('Country')}
                                isRequired
                                name={nameof<HotelModel>('countryId')}
                                error={errors.countryId ? errors.countryId.message : ''}
                                options={countries.countriesOptions}
                                control={control}
                                onChange={(value: SelectOptionModel) => {
                                    const countryId = value.value as number
                                    onChangeCountry(countryId)
                                    setValue("cityId", 0)
                                    setValue('regionId', 0)
                                }}
                            />
                        </Col>
                        <Col type="50">
                            <SelectFormFieldController
                                label={Localization.getString('City')}
                                isRequired
                                name={nameof<HotelModel>('cityId')}
                                error={errors.cityId ? errors.cityId.message : ''}
                                options={cities.citiesInCountryWithRegionsOptions.get(countryId) || []}
                                control={control}
                                onChange={(value: SelectOptionModel) => {
                                    setValue('regionId', 0)
                                }}
                            />
                        </Col>
                    </Row>
                    {regionsOptions && regionsOptions.length > 0
                        ? <Row>
                            <Col type="50">
                                <SelectFormFieldController
                                    label={Localization.getString('Region')}
                                    isRequired
                                    name={nameof<HotelModel>('regionId')}
                                    error={errors.regionId ? errors.regionId.message : ''}
                                    options={regionsOptions}
                                    control={control}
                                />
                            </Col></Row>
                        : undefined}
                    <Row>
                        <Col type="50">
                            <TextFormField
                                label={Localization.getString('Phone')}
                                // placeholder={Localization.getString('United Arab Emirates')}
                                // subLabel={`${Localization.getString('Country name in')} ${language.name}`}
                                isRequired
                                error={errors.phone ? errors.phone.message : ''}
                                // defaultValue={tour.nameInternal}
                                name={nameof<HotelModel>('phone')}
                                register={register}
                                options={{
                                    required: Localization.getString('Phone'),
                                }}
                            />
                        </Col>
                        <Col type="50">
                            <TextFormField
                                label={Localization.getString('Email')}
                                // placeholder={Localization.getString('United Arab Emirates')}
                                // subLabel={`${Localization.getString('Country name in')} ${language.name}`}
                                isRequired
                                error={errors.email ? errors.email.message : ''}
                                // defaultValue={tour.nameInternal}
                                name={nameof<HotelModel>('email')}
                                register={register}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col type="100">
                            <FormFieldWrapper>
                                <MapsLocationPicker
                                    apiKey={"AIzaSyDZAA6MEKfk75MTQ7_BiCP_JUbwsfLQQy0"}
                                    onChange={onSetLocation}
                                    value={location}
                                />
                            </FormFieldWrapper>
                        </Col>
                    </Row>
                    <Buttons.StickySaveButton>{Localization.getString('Save')}</Buttons.StickySaveButton>
                </form>
            </Grid>
        </PopupFormWrapper>
    </Popup>
}
export default HotelFormPage