import { apiCallWrapper } from 'traveldesk-ui'
import { TourCategoryModel } from './../models/TourCategoryModel'
export default class TourCategoriesApiService {
    public static async Save(tc: TourCategoryModel, dispatch: any) {
        return await apiCallWrapper<TourCategoryModel>(`/api/tourscategories`, 'POST', dispatch, tc)
    }
    public static async getManageList(dispatch: any) {
        const res = await apiCallWrapper<TourCategoryModel[]>('/api/tourscategories', 'GET', dispatch)
        if (res.Content && res.Content.length) {
            res.Content = res.Content.map((x) => TourCategoryModel.Create(x))
        }
        return res
    }
}
