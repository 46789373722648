import { CityModel } from './../models/CityModel'
import { apiCallWrapper } from 'traveldesk-ui'
import CountryModel from '../models/CountryModel'
import CountryOptionModel from '../models/CountryOptionModel'
export default class CountriesApiService {
    public static async Save(country: CountryModel, dispatch: any) {
        return await apiCallWrapper<CountryModel>(`/api/countries`, 'POST', dispatch, country)
    }
    public static async getCities(countryId: number, dispatch: any) {
        return await apiCallWrapper<CityModel[]>(`/api/cities?countryId=${countryId}`, 'GET', dispatch)
    }
    public static async getOptions(dispatch: any) {
        return await apiCallWrapper<CountryOptionModel[]>(`/api/countries/options`, 'GET', dispatch)
    }
    public static async getManageList(dispatch: any) {
        const res = await apiCallWrapper<CountryModel[]>('/api/countries', 'GET', dispatch)
        if (res.Content && res.Content.length) {
            res.Content = res.Content.map((x) => CountryModel.Create(x))
        }
        return res
    }
}
