import { apiCallWrapper, defaultLabelSort, SelectOptionModel } from 'traveldesk-ui'
import { AppThunkAction } from '.'
import { Action, Reducer } from 'redux'
import CurrencyModel from '../models/CurrencyModel'

export interface State {
    data: CurrencyModel[]
    options: SelectOptionModel[]
    isLoading: boolean
}
const REQUEST_CURRENCIES = 'A_REQUEST_CURRENCIES'
interface RequestCurrencies {
    type: 'A_REQUEST_CURRENCIES'
}
const RECEIVE_CURRENCIES = 'A_RECEIVE_CURRENCIES'
interface ReceiveCurrencies {
    type: 'A_RECEIVE_CURRENCIES'
    payload: CurrencyModel[]
}
const UPDATE_CURRENCY = 'A_UPDATE_CURRENCY'
interface UpdateCurrency {
    type: 'A_UPDATE_CURRENCY'
    payload: CurrencyModel
}

type KnownAction = ReceiveCurrencies | RequestCurrencies | UpdateCurrency

export const actionCreators = {
    requestData: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: REQUEST_CURRENCIES })
        const result = await apiCallWrapper<CurrencyModel[]>('/api/currencies', 'GET', dispatch)
        if (result.IsOk) {
            dispatch({ type: RECEIVE_CURRENCIES, payload: result.Content || [] })
        }
    },
    saveData: (obj: CurrencyModel): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: REQUEST_CURRENCIES })
        const result = await apiCallWrapper<CurrencyModel>('/api/currencies', 'POST', dispatch, obj)
        if (result.IsOk && result.Content) {
            dispatch({ type: UPDATE_CURRENCY, payload: result.Content })
        }
    },
}

const unloadedState: State = { data: [] as CurrencyModel[], options: [] as SelectOptionModel[], isLoading: false }
export const reducer: Reducer<State> = (state: State | undefined, incomingAction: Action): State => {
    if (state === undefined) {
        return unloadedState
    }

    const action = incomingAction as KnownAction
    switch (action.type) {
        case REQUEST_CURRENCIES:
            return {
                ...state,
                isLoading: true,
            }
        case RECEIVE_CURRENCIES:
            return {
                ...state,
                data: action.payload.map((x) => CurrencyModel.Create(x)),
                options: action.payload.map((x) => ({ value: x.id, label: x.code })).sort(defaultLabelSort),
                isLoading: false,
            }
        case UPDATE_CURRENCY:
            const currency = CurrencyModel.Create(action.payload)
            return {
                ...state,
                data: state.data.map((x) => (x.id == currency.id ? currency : x)),
                options: state.options.map((x) =>
                    x.value == currency.id ? { value: currency.id, label: currency.code } : x,
                ),
            }
        default:
            return state
    }
}
