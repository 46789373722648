import * as React from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { Col, Grid, IdFormField, Popup, PopupFormWrapper, Row, TextAreaField, TextFormField } from 'traveldesk-ui'
import { urls } from '../urls'
import { ApplicationState } from '../store'
import { MetaDataModelCity } from '../models/MetaDataModel'
import { nameof } from 'traveldesk-ui'
import { Buttons } from 'traveldesk-ui'
import { actionCreators as citiesActions } from '../store/Cities'

interface Props extends RouteComponentProps<{ cityId: string; language: string }> { }
const CityTranslationFormPage = (props: Props) => {
    const dispatch = useDispatch()
    const cityId = parseInt(props.match.params.cityId)
    const languages = useSelector((state: ApplicationState) => state.languages.data)
    const city = useSelector((state: ApplicationState) => state.cities.edit)
    const language = React.useMemo(() => {
        return languages.find((x) => x.id == parseInt(props.match.params.language))
    }, [languages, props.match.params.language])
    const Localization = useSelector((state: ApplicationState) => state.localization.locale)
    const { handleSubmit, formState: { errors }, control, register, getValues, setValue, watch, reset, setError } = useForm()
    const onSubmit = (data: any) => {
        if (city) {
            const model = Object.assign(new MetaDataModelCity(), data, { cityId: city.id })
            dispatch(citiesActions.saveTranslation(city.countryId, city.id, model))
        }
    }
    React.useEffect(() => {
        const metaData =
            city?.metaData.find((x) => x.languageId == language?.id) ||
            Object.assign(new MetaDataModelCity(), { languageId: language?.id })
        reset(metaData)
    }, [city, language])
    return city && language ? (
        <Popup onClose={() => props.history.push(urls.cityForm(city.countryId, cityId))}>
            <PopupFormWrapper>
                <Grid>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col type="100">
                                <h3>{`${Localization.getString('Manage')} ${city.name} ${Localization.getString(
                                    'meta data in',
                                )} ${language.name}`}</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col type="50">
                                <IdFormField name="languageId" control={control} />
                                <TextFormField
                                    label={Localization.getString('City name')}
                                    placeholder={Localization.getString('Abu Dhabi')}
                                    subLabel={`${Localization.getString('City name in')} ${language.name}`}
                                    isRequired
                                    error={errors.name ? errors.name.message : ''}
                                    // defaultValue={tour.nameInternal}
                                    name={nameof<MetaDataModelCity>('name')}
                                    register={register}
                                    options={{
                                        required: Localization.getString('City name is required'),
                                    }}
                                />
                                <TextFormField
                                    label={Localization.getString('Page title')}
                                    // placeholder={Localization.getString('United Arab Emirates')}
                                    subLabel={`${Localization.getString('City page title in')} ${language.name}`}
                                    isRequired
                                    error={errors.pageTitle ? errors.pageTitle.message : ''}
                                    // defaultValue={tour.nameInternal}
                                    name={nameof<MetaDataModelCity>('pageTitle')}
                                    register={register}
                                    options={{
                                        required: Localization.getString('Page title is required'),
                                    }}
                                />
                            </Col>
                            <Col type="50">
                                <TextAreaField
                                    label={Localization.getString('Short Description')}
                                    subLabel={`${Localization.getString('City short description in')} ${language.name}`}
                                    name={nameof<MetaDataModelCity>('shortDescription')}
                                    register={register}
                                />
                                <TextAreaField
                                    label={Localization.getString('Description')}
                                    subLabel={`${Localization.getString('City long description in')} ${language.name}`}
                                    name={nameof<MetaDataModelCity>('description')}
                                    register={register}
                                />
                            </Col>
                        </Row>
                        <Buttons.StickySaveButton>{Localization.getString('Save')}</Buttons.StickySaveButton>
                    </form>
                </Grid>
            </PopupFormWrapper>
        </Popup>
    ) : null
}
export default CityTranslationFormPage
