import { Action, Reducer } from 'redux'
import InventoryModel from '../models/InventoryModel'
import { AppThunkAction } from '.'
import InventoryApiService from '../services/InventoryApiService'
import { EntitiesPaginatedModel } from 'traveldesk-ui'
import InventoryFilter from './../viewModels/InventoryFilter'
export interface State {
    data: EntitiesPaginatedModel<InventoryModel>
    isLoading: boolean
}
interface RequestInventoryAction {
    type: 'REQUEST_INVENTORY'
}
interface ReceiveInventoryAction {
    type: 'RECEIVE_INVENTORY'
    payload: EntitiesPaginatedModel<InventoryModel>
}
interface UpdateInventoryAction {
    type: 'UPDATE_INVENTORY'
    payload: InventoryModel
}

type KnownAction = RequestInventoryAction | ReceiveInventoryAction | UpdateInventoryAction
export const actionCreators = {
    requestData: (filter: InventoryFilter): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let fetchTask = await InventoryApiService.getList(filter, dispatch)
        if (fetchTask.IsOk && fetchTask.Content) {
            dispatch({
                type: 'RECEIVE_INVENTORY',
                payload: fetchTask.Content,
            })
        }
    },
    update: (model: InventoryModel): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let fetchTask = await InventoryApiService.update(model, dispatch)
        if (fetchTask.IsOk && fetchTask.Content) {
            dispatch({
                type: 'UPDATE_INVENTORY',
                payload: fetchTask.Content,
            })
        }
    },
}

const unloadedState: State = { data: new EntitiesPaginatedModel<InventoryModel>(), isLoading: false }

export const reducer: Reducer<State> = (state: State | undefined, incomingAction: Action): State => {
    if (state === undefined) {
        return unloadedState
    }

    const action = incomingAction as KnownAction
    switch (action.type) {
        case 'REQUEST_INVENTORY':
            return {
                ...state,
                isLoading: true,
            }
        case 'UPDATE_INVENTORY':
            return {
                ...state,
                isLoading: false,
                data: Object.assign(new EntitiesPaginatedModel(), {
                    entities: state.data.entities.map((x) => (x.id == action.payload.id ? action.payload : x)),
                    pagingInfo: state.data.pagingInfo,
                }),
            }
        case 'RECEIVE_INVENTORY':
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
            return {
                ...state,
                data: action.payload,
                isLoading: false,
            }
            break
    }

    return state
}
