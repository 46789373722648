import { CityModel } from './../models/CityModel'
import { Action, Reducer } from 'redux'
import { AppThunkAction } from './'
import { SelectOptionModel, defaultLabelSort } from 'traveldesk-ui'
import { apiCallWrapper } from 'traveldesk-ui'
import CitiesApiService from '../services/CitiesApiService'
import { MetaDataModelCity } from '../models/MetaDataModel'
import { CallHistoryMethodAction, push } from 'connected-react-router'
import { urls } from '../urls'
import ICityOptionModel from '../models/CityWithRegionsOptionModel'

export interface State {
    citiesInCountryWithRegionsOptions: Map<number, ICityOptionModel[]>
    citiesOptions: SelectOptionModel[]
    citiesHash: Map<number, string>
    edit?: CityModel
}
const CITY_EDIT = 'A_CITY_EDIT'
interface CityEdit {
    type: 'A_CITY_EDIT'
    payload?: CityModel
}
export const CITY_TRANSLATION_SAVE = 'A_CITY_TRANSLATION_SAVE'
export interface CityTranslationSave {
    type: 'A_CITY_TRANSLATION_SAVE'
    payload: MetaDataModelCity
}
export const CITY_UPDATED = 'A_CITY_UPDATED'
export interface CityUpdated {
    type: 'A_CITY_UPDATED'
    payload: CityModel
}
const RECEIVE_CITIES_OPTIONS = 'A_RECEIVE_CITIES_OPTIONS'
interface ReceiveCitiesOptionsAction {
    type: 'A_RECEIVE_CITIES_OPTIONS'
    payload: SelectOptionModel[]
}
const RECEIVE_CITIES_OPTIONS_INCOUNTRYWITHREGIONS = "A_RECEIVE_CITIES_OPTIONS_INCOUNTRYWITHREGIONS"
interface ReceiveCitiesOptionsInCountryWithRegions {
    type: "A_RECEIVE_CITIES_OPTIONS_INCOUNTRYWITHREGIONS"
    countryId: number
    payload: ICityOptionModel[]
}
type KnownAction = ReceiveCitiesOptionsAction | CityEdit | CityUpdated | CityTranslationSave | CallHistoryMethodAction | ReceiveCitiesOptionsInCountryWithRegions

export const actionCreators = {
    saveData: (country: CityModel): AppThunkAction<KnownAction> => async (dispatch) => {
        let fetchTask = await CitiesApiService.Save(country, dispatch)
        if (fetchTask.IsOk && fetchTask.Content) {
            dispatch({
                type: CITY_UPDATED,
                payload: fetchTask.Content,
            })
            dispatch(push(urls.cityForm(fetchTask.Content.countryId, fetchTask.Content.id)))
        }
    },
    saveTranslation: (
        countryId: number,
        cityId: number,
        metaData: MetaDataModelCity,
    ): AppThunkAction<KnownAction> => async (dispatch) => {
        dispatch({ type: CITY_TRANSLATION_SAVE, payload: metaData } as CityTranslationSave)
        dispatch(push(urls.cityForm(countryId, cityId)))
    },
    editCity: (city: CityModel | undefined) => ({ type: 'A_CITY_EDIT', payload: city }),
    requestCitiesOptionsInCountryWithRegions: (countryId: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState()
        if (appState && appState.cities && !appState.cities.citiesInCountryWithRegionsOptions.get(countryId)) {
            const result = await apiCallWrapper<ICityOptionModel[]>(`/api/cities/optionsincountrywithregions?countryid=${countryId}`, 'GET', dispatch)
            if (result.IsOk) {
                dispatch({ type: RECEIVE_CITIES_OPTIONS_INCOUNTRYWITHREGIONS, countryId, payload: result.Content || [] })
            }
        }
    },
    requestCitiesOptions: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState()
        if (appState && appState.cities && appState.cities.citiesOptions.length === 0) {
            const result = await apiCallWrapper<SelectOptionModel[]>('/api/cities/options', 'GET', dispatch)
            if (result.IsOk) {
                dispatch({ type: RECEIVE_CITIES_OPTIONS, payload: result.Content || [] })
            }
        }
    },
}

const unloadedState: State = {
    citiesInCountryWithRegionsOptions: new Map<number, ICityOptionModel[]>(),
    citiesOptions: [] as SelectOptionModel[],
    citiesHash: new Map<number, string>()
}

export const reducer: Reducer<State> = (state: State | undefined, incomingAction: Action): State => {
    if (state === undefined) {
        return unloadedState
    }

    const action = incomingAction as KnownAction
    switch (action.type) {
        case CITY_TRANSLATION_SAVE:
            if (state.edit) {
                const metaData = state.edit.metaData.some((x) => x.languageId == action.payload.languageId)
                    ? state.edit.metaData.map((x) => (x.languageId == action.payload.languageId ? action.payload : x))
                    : state.edit.metaData.concat([action.payload])
                const edit = Object.assign(CityModel.Create(state.edit), { metaData })
                return {
                    ...state,
                    edit,
                }
            }
            return state
        case CITY_UPDATED:
            return state
        case CITY_EDIT:
            return {
                ...state,
                edit: action.payload,
            }
        case RECEIVE_CITIES_OPTIONS_INCOUNTRYWITHREGIONS:
            const citiesInCountryWithRegionsOptions = Object.assign(new Map<number, ICityOptionModel[]>(), state.citiesInCountryWithRegionsOptions)
            const citiesWithRegionsOptions = action.payload.sort(defaultLabelSort)
            citiesWithRegionsOptions.forEach(x => x.regions = x.regions.sort(defaultLabelSort))
            citiesInCountryWithRegionsOptions.set(action.countryId, citiesWithRegionsOptions)
            const newState = {
                ...state,
                citiesInCountryWithRegionsOptions
            }
            return newState
        case RECEIVE_CITIES_OPTIONS:
            return {
                ...state,
                citiesOptions: action.payload,
                citiesHash: action.payload.reduce((res, next) => {
                    res.set(next.value as number, next.label)
                    return res
                }, new Map<number, string>()),
            }
        default:
            return state
    }
}
