import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ApplicationState } from '../store'
import { DataTable, DataTableColumnModel, DataTableModel, FilterType, DataHeaderGroup, InputType, SelectOptionModel, PInTableList } from 'traveldesk-ui'
import { actionCreators as inventoryActions } from '../store/Inventory'
import InventoryFilter from '../viewModels/InventoryFilter'
import { capitalize } from 'traveldesk-ui'
import { actionCreators as countriesActions } from '../store/Countries'
import { actionCreators as citiesActions } from '../store/Cities'
import InventoryModel from '../models/InventoryModel'
import styled from 'styled-components'
import LanguagesApiService from '../services/LanguagesApiService'
import ToursApiService from '../services/ToursApiService'
import TourMiniDisplayModel from '../models/TourMiniDisplayModel'
import OfferMiniDisplayModel from '../models/OfferMiniDisplayModel'
import OffersApiService from '../services/OffersApiService'
const PricePlaceHolder = styled.span`
    color: #aaa;
`
interface Props { }
const InventoryPage = (props: Props) => {
    const Localization = useSelector((state: ApplicationState) => state.localization.locale)
    const inventory = useSelector((state: ApplicationState) => state.inventory.data)
    const [filter, setFilter] = React.useState(new InventoryFilter())
    const countries = useSelector((state: ApplicationState) => state.countries)
    const cities = useSelector((state: ApplicationState) => state.cities)
    const [toursOptions, setToursOptions] = React.useState<SelectOptionModel[]>([])
    const [toursHash, setToursHash] = React.useState<Map<number, TourMiniDisplayModel>>(new Map<number, TourMiniDisplayModel>())
    const [offersOptions, setOffersOptions] = React.useState<SelectOptionModel[]>([])
    const [offersHash, setOffersHash] = React.useState<Map<number, OfferMiniDisplayModel>>(new Map<number, OfferMiniDisplayModel>())
    const [languagesOptions, setLanguagesOptions] = React.useState<SelectOptionModel[]>([])
    const [languagesDisplayHash, setLanguagesDisplayHash] = React.useState<Map<number, string>>(new Map<number, string>())
    const dispatch = useDispatch()
    const getOffersOptions = async () => {
        const offersMiniDisplay = await OffersApiService.getAllMiniDisplay(dispatch)
        if (offersMiniDisplay.IsOk && offersMiniDisplay.Content) {
            const options = offersMiniDisplay.Content.map(x => ({ value: x.id, label: `${x.nameInternal} (${x.id})` }))
            setOffersOptions(options)
            setOffersHash(offersMiniDisplay.Content.reduce((res, next) => {
                res.set(next.id, next)
                return res
            }, new Map<number, TourMiniDisplayModel>()))
        }
        else {
            alert(offersMiniDisplay.ErrorMessage)
        }
    }
    const getToursOptions = async () => {
        const toursMiniDisplay = await ToursApiService.getAllMiniDisplay(dispatch)
        if (toursMiniDisplay.IsOk && toursMiniDisplay.Content) {
            const options = toursMiniDisplay.Content.map(x => ({ value: x.id, label: `${x.nameInternal} (${x.id})` }))
            setToursOptions(options)
            setToursHash(toursMiniDisplay.Content.reduce((res, next) => {
                res.set(next.id, next)
                return res
            }, new Map<number, TourMiniDisplayModel>()))
        }
        else {
            alert(toursMiniDisplay.ErrorMessage)
        }
    }
    const getLanguagesOptions = async () => {
        const languages = await LanguagesApiService.getForInventory(dispatch)
        if (languages.IsOk && languages.Content) {
            setLanguagesOptions(languages.Content.map(x => ({ label: x.code, value: x.id })))
            setLanguagesDisplayHash(languages.Content.reduce((res, next) => {
                res.set(next.id, next.code)
                return res
            }, new Map<number, string>()))
        }
        else {
            alert(languages.ErrorMessage)
        }
    }
    const getData = () => {
        dispatch(inventoryActions.requestData(filter))
    }
    React.useEffect(() => {
        if (countries.countriesOptions.length == 0) {
            dispatch(countriesActions.requestCountriesOptions())
        }
        if (cities.citiesOptions.length == 0) {
            dispatch(citiesActions.requestCitiesOptions())
        }
        getLanguagesOptions()
        getToursOptions()
        getOffersOptions()
    }, [])
    React.useEffect(() => {
        getData()
    }, [filter])
    const onRefresh = () => {
        getData()
    }
    const onPageChange = (page: number) => {
        setFilter(Object.assign(new InventoryFilter(), filter, { Page: page }))
    }
    const onClearFilter = () => {
        setFilter(new InventoryFilter())
    }
    const onFilterValueChange = (fieldName: string, value: any) => {
        const fName = capitalize(fieldName)
        if (fieldName == 'periodStart' || fieldName == 'periodEnd') {
            setFilter(
                Object.assign(new InventoryFilter(), filter, {
                    [fName]: value.value,
                    // [fName]: { StartDate: moment(value.value.startDate), EndDate: moment(value.value.endDate) },
                }),
            )
        } else {
            filter.SetFilter(fieldName, value)
            setFilter(Object.assign(new InventoryFilter(), filter))
        }
    }
    const onSortChanged = (fieldName: string, sortType: string) => {
        setFilter(Object.assign(new InventoryFilter(), filter, { SortField: fieldName, SortType: sortType }))
    }
    const onEdit = (prevObj: InventoryModel, newObj: InventoryModel) => {
        dispatch(inventoryActions.update(newObj))
    }
    const renderB2cPrice = (type: 'Adult' | 'Child' | 'Trip') => (obj: InventoryModel) => {
        const b2cField = `pricePer${type}UsdB2C`
        const recField = `pricePer${type}RecUsd`
        const b2c = (obj as any)[b2cField] as number | undefined
        const rec = (obj as any)[recField] as number
        return obj.isPublishedB2C ? (
            typeof b2c != 'undefined' ? (
                <span>{b2c}</span>
            ) : (
                <PricePlaceHolder>{rec}</PricePlaceHolder>
            )
        ) : null
    }
    const initDataTable = () => {
        const headerGroups = [[
            { Title: '', ColSpan: 9 },
            { Title: 'Rec. Published Rate', ColSpan: 3 },
            { Title: 'Cost Price', ColSpan: 3 },
            { Title: '', ColSpan: 1 },
            { Title: 'Actual Published Rate', ColSpan: 3 },
            { Title: 'Discount', ColSpan: 3 },
        ]]
        const columns = [
            new DataTableColumnModel({
                fieldName: 'countryId',
                displayName: Localization.getString('Country'),
                customRenderer: (obj: InventoryModel) => {
                    return obj.countries.map((c) => countries.countriesHash.get(c)).join(',')
                },
                filterType: FilterType.MULTISELECTNUMBER,
                filterValue: filter.GetMultiselectFilterNumberValue("countryId"),
                filterOptions: countries.countriesWithToursOptions
            }),
            new DataTableColumnModel({
                fieldName: 'cityId',
                displayName: Localization.getString('City'),
                customRenderer: (obj: InventoryModel) => {
                    return obj.cities.map((c) => cities.citiesHash.get(c)).join(',')
                },
                filterType: FilterType.MULTISELECTNUMBER,
                filterValue: filter.GetMultiselectFilterNumberValue("cityId"),
                filterOptions: cities.citiesOptions
            }),
            new DataTableColumnModel({
                fieldName: 'supplierName',
                filterType: FilterType.TEXT,
                displayName: Localization.getString('Supplier Name'),
                filterValue: filter.GetTextFilterValue('supplierName'),
            }),
            new DataTableColumnModel({
                fieldName: 'tourName',
                filterType: FilterType.MULTISELECTNUMBER,
                displayName: Localization.getString('Tour'),
                filterValue: filter.GetMultiselectFilterNumberValue('tourId'),
                filterFieldName: "tourId",
                customRenderer: (obj: InventoryModel) => <React.Fragment>
                    <a target='_blank' href={`https://traveldesk.me/${languagesDisplayHash.get(obj.languageId)}/tour/${obj.tourUrl}`}>{obj.tourName}</a>
                    <a title="Edit tour" style={{ float: 'right' }} href={`https://b2b.traveldesk.me/tours/${obj.tourId}`} target='_blank'><i className='fa fa-pencil clickable' /></a>
                    <PInTableList>{toursHash.get(obj.tourId)?.nameDisplay}</PInTableList>
                </React.Fragment>,
                width: 250,
                filterOptions: toursOptions,
                sortable: true
            }),
            new DataTableColumnModel({
                fieldName: "languageId",
                filterType: FilterType.MULTISELECTNUMBER,
                filterOptions: languagesOptions,
                filterValue: filter.GetMultiselectFilterNumberValue('languageId'),
                displayName: Localization.getString("Lang"),
                customRenderer: (obj: InventoryModel) => languagesDisplayHash.get(obj.languageId)
            }),
            new DataTableColumnModel({
                fieldName: 'offerName',
                filterFieldName: "offerId",
                filterType: FilterType.MULTISELECTNUMBER,
                displayName: Localization.getString('Ticket'),
                filterValue: filter.GetMultiselectFilterNumberValue('offerId'),
                customRenderer: (obj: InventoryModel) => <React.Fragment>
                    <strong>{`${obj.offerName} (${obj.offerId})`}</strong><a title="Edit offer" style={{ float: "right" }} href={`https://b2b.traveldesk.me/tours/${obj.tourId}/${obj.offerId}`} target='_blank'><i className='fa fa-pencil clickable' /></a>
                    <PInTableList>{offersHash.get(obj.offerId)?.nameDisplay}</PInTableList>
                </React.Fragment>,
                width: 250,
                filterOptions: offersOptions,
                sortable: true
            }),
            new DataTableColumnModel({
                fieldName: 'periodStart',
                displayName: Localization.getString('Valid From'),
                filterType: FilterType.DATERANGE,
                filterValue: filter.PeriodStart,
            }),
            new DataTableColumnModel({
                fieldName: 'periodEnd',
                displayName: Localization.getString('Valid To'),
                filterType: FilterType.DATERANGE,
                filterValue: filter.PeriodEnd,
            }),
            new DataTableColumnModel({
                fieldName: 'isLiveBook',
                displayName: Localization.getString('Availability Settings'),
                filterType: FilterType.MULTISELECT,
                filterOptions: [
                    { value: 1, label: 'Live' },
                    { value: 0, label: 'Manual' },
                ],
                filterValue: filter.GetMultiselectFilterValue("isLiveBook"),
            }),
            new DataTableColumnModel({
                fieldName: 'pricePerAdultRecUsd',
                displayName: Localization.getString('Ad'),
            }),
            new DataTableColumnModel({
                fieldName: 'pricePerChildRecUsd',
                displayName: Localization.getString('Ch'),
            }),
            new DataTableColumnModel({
                fieldName: 'pricePerTripRecUsd',
                displayName: Localization.getString('Trip'),
            }),
            new DataTableColumnModel({
                fieldName: 'netPricePerAdult',
                displayName: Localization.getString('Ad'),
            }),
            new DataTableColumnModel({
                fieldName: 'netPricePerChild',
                displayName: Localization.getString('Ch'),
            }),
            new DataTableColumnModel({
                fieldName: 'netPricePerTrip',
                displayName: Localization.getString('Trip'),
            }),
            new DataTableColumnModel({
                fieldName: 'isPublishedB2C',
                displayName: Localization.getString('Publish Status'),
                filterType: FilterType.MULTISELECT,
                filterOptions: [
                    { value: 1, label: 'On' },
                    { value: 0, label: 'Off' },
                ],
                isEditable: true,
                editType: InputType.Checkbox,
                filterValue: filter.GetMultiselectFilterValue("isPublishedB2C"),
                customRenderer: (obj: InventoryModel) => {
                    return obj.isPublishedB2C ? Localization.getString('On') : Localization.getString('Off')
                },
            }),
            new DataTableColumnModel({
                fieldName: 'pricePerAdultUsdB2C',
                displayName: Localization.getString('Ad'),
                isEditable: true,
                editType: InputType.Number,
                placeholder: 'pricePerAdultRecUsd',
                isEditableClause: (obj: InventoryModel) => obj.isPublishedB2C,
                customRenderer: renderB2cPrice('Adult'),
            }),
            new DataTableColumnModel({
                fieldName: 'pricePerChildUsdB2C',
                displayName: Localization.getString('Ch'),
                isEditable: true,
                editType: InputType.Number,
                placeholder: 'pricePerChildRecUsd',
                isEditableClause: (obj: InventoryModel) => obj.isPublishedB2C,
                customRenderer: renderB2cPrice('Child'),
            }),
            new DataTableColumnModel({
                fieldName: 'pricePerTripUsdB2C',
                displayName: Localization.getString('Trip'),
                isEditable: true,
                editType: InputType.Number,
                placeholder: 'pricePerTripRecUsd',
                isEditableClause: (obj: InventoryModel) => obj.isPublishedB2C,
                customRenderer: renderB2cPrice('Trip'),
            }),
            new DataTableColumnModel({
                fieldName: 'discountPercent',
                displayName: '%',
                isEditable: true,
                editType: InputType.Number,
                editStep: 1,
                isEditableClause: (obj: InventoryModel) => obj.isPublishedB2C,
            }),
            new DataTableColumnModel({
                fieldName: 'discountFrom',
                displayName: Localization.getString('From'),
                editType: InputType.Date,
            }),
            new DataTableColumnModel({
                fieldName: 'discountTo',
                displayName: Localization.getString('Until'),
                editType: InputType.Date,
            }),
        ]
        return new DataTableModel({
            name: Localization.getString('Inventory'),
            fileName: 'inventory.xls',
            columns,
            headerGroups,
        })
    }
    const dataTableModel = React.useMemo(() => initDataTable(), [filter, countries, cities, languagesDisplayHash, toursHash, offersHash, offersOptions, toursOptions])
    return (
        <DataTable
            isFullScreen
            onClearFilter={onClearFilter}
            data={inventory.entities}
            pagingInfo={inventory.pagingInfo}
            dataTable={dataTableModel}
            onFilterValueChange={onFilterValueChange}
            onRefreshData={onRefresh}
            onPageChange={onPageChange}
            onSortChanged={onSortChanged}
            onEdit={onEdit}
        />
    )
}
export default InventoryPage
