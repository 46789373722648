import * as React from 'react'
import HotelsFilter from '../viewModels/HotelsFilter'
import { useDispatch, useSelector } from 'react-redux'
import { actionCreators as hotelsActions } from '../store/Hotels'
import { actionCreators as countriesActions } from '../store/Countries'
import { actionCreators as citiesActions } from '../store/Cities'
import { ApplicationState } from '../store'
import { DataTable, DataTableColumnModel, DataTableModel, DataTableModelCustomAction, FilterType, Select, SelectOptionModel, capitalize, defaultLabelSort } from 'traveldesk-ui'
import ICityOptionModel from '../models/CityWithRegionsOptionModel'
import HotelModel from '../models/HotelModel'
import HotelFormPage from './HotelFormPage'
import { RouteComponentProps } from 'react-router'
import { Link } from 'react-router-dom'
import { urls } from '../urls'
interface Props extends RouteComponentProps<{ countryId: string }> { }
const HotelsPage = (props: Props) => {
    React.useEffect(() => {
        dispatch(countriesActions.requestCountriesOptions())
    }, [])
    const [filter, setFilter] = React.useState(new HotelsFilter())
    const countries = useSelector((state: ApplicationState) => state.countries)
    const cities = useSelector((state: ApplicationState) => state.cities)
    const hotels = useSelector((state: ApplicationState) => state.hotels).hotels
    const dispatch = useDispatch()
    React.useEffect(() => {
        getData()
    }, [filter])
    React.useEffect(() => {
        if (props.location.pathname == urls.hotels) {
            getData()
        }
    }, [props.location.pathname])
    React.useEffect(() => {
        const countryId = parseInt(props.match.params.countryId ?? "0")
        filter.countryId = countryId
        setFilter(Object.assign(new HotelsFilter(), filter))
        dispatch(citiesActions.requestCitiesOptionsInCountryWithRegions(filter.countryId))
    }, [props.match.params.countryId])
    const getData = () => {
        if (filter.countryId) {
            dispatch(hotelsActions.requestData(filter))
        }
    }
    const onChangeCountry = (option: SelectOptionModel) => {
        props.history.push(`/hotels/${option.value}`)

    }

    const initDataTable = () => {
        const countryId = filter.countryId
        const countriesOptions = countries.countriesOptions
        const citiesOptions = cities.citiesInCountryWithRegionsOptions.get(countryId)
        const cityIds = filter.GetMultiselectFilterNumberValue("CityId")
        const regionsOptions = (cityIds && cityIds.length > 0)
            ? citiesOptions?.filter(x => cityIds?.indexOf(x.value as number) >= 0)?.reduce((res: SelectOptionModel[], next: ICityOptionModel) => {
                res = res.concat(next.regions)
                return res
            }, [] as SelectOptionModel[]).sort(defaultLabelSort)
            : undefined
        const columns = [new DataTableColumnModel({
            fieldName: "name",
            displayName: "Name",
            filterType: FilterType.TEXT,
            filterFieldName: "Name",
            filterValue: filter.GetTextFilterValue("Name"),
            sortable: true,
            width: 200
        }),
        new DataTableColumnModel({
            fieldName: "cityName",
            displayName: "City",
            filterFieldName: "CityId",
            filterType: (citiesOptions && citiesOptions.length > 0) ? FilterType.MULTISELECTNUMBER : undefined,
            filterValue: (citiesOptions && citiesOptions.length > 0) ? filter.GetMultiselectFilterNumberValue("CityId") : undefined,
            filterOptions: citiesOptions,
            width: 100
        }),
        new DataTableColumnModel({
            fieldName: "regionName",
            displayName: "Region",
            filterFieldName: "RegionId",
            filterType: (regionsOptions && regionsOptions.length > 0) ? FilterType.MULTISELECTNUMBER : undefined,
            filterValue: (regionsOptions && regionsOptions.length > 0) ? filter.GetMultiselectFilterNumberValue("RegionId") : undefined,
            filterOptions: regionsOptions,
            width: 100
        }),
        new DataTableColumnModel({
            fieldName: "address",
            displayName: "Address",
            filterFieldName: "Adr_Address1",
            filterType: FilterType.TEXT,
            filterValue: filter.GetTextFilterValue("Adr_Address1"),
            width: 200
        }),
        new DataTableColumnModel({
            fieldName: "phone",
            displayName: "Phone",
            filterFieldName: "Adr_Telephone",
            filterType: FilterType.TEXT,
            filterValue: filter.GetTextFilterValue("Adr_Telephone"),
            width: 100
        }),
        new DataTableColumnModel({
            fieldName: "email",
            displayName: "Email",
            filterFieldName: "Adr_Email",
            filterType: FilterType.TEXT,
            filterValue: filter.GetTextFilterValue("Adr_Email"),
            width: 100
        }),
        new DataTableColumnModel({
            fieldName: "",
            displayName: "",
            customRenderer: (obj: HotelModel) => <i onClick={() => props.history.push(urls.hotelForm(obj.id, parseInt(props.match.params.countryId)))} className='fa fa-pencil clickable' />,
            width: 40
        })
        ]
        return new DataTableModel({
            columns,
            name: "Hotels",
            fileName: "hotels.xlsx",
            customActions: [
                new DataTableModelCustomAction(
                    () => { },
                    () => filter.countryId > 0
                        ? <Link to={`${urls.hotelForm(0, filter.countryId || 0)}`}><i className='fa fa-plus clickable' /></Link>
                        : <i className='fa fa-click disabled' />,
                    "Add new hotel"
                )
            ],
            customToolBarComponents: [
                () => <Select appendClass='myselectinput' options={countriesOptions} placeholder={"Select country"} onChange={onChangeCountry} value={filter.countryId} />
            ]
        })
    }
    const onRefresh = () => {
        getData()
    }
    const onPageChange = (page: number) => {
        setFilter(Object.assign(new HotelsFilter(), filter, { Page: page }))
    }
    const onClearFilter = () => {
        setFilter(Object.assign(new HotelsFilter(), { countryId: filter.countryId }))
    }
    const onFilterValueChange = (fieldName: string, value: any) => {

        filter.SetFilter(fieldName, value)
        setFilter(Object.assign(new HotelsFilter(), filter))
    }
    const onSortChanged = (fieldName: string, sortType: string) => {
        setFilter(Object.assign(new HotelsFilter(), filter, { SortField: fieldName, SortType: sortType }))
    }
    const dataTableModel = React.useMemo(() => initDataTable(), [filter, cities.citiesInCountryWithRegionsOptions, countries.countriesOptions])
    return <React.Fragment>
        <DataTable
            isFullScreen
            onClearFilter={onClearFilter}
            data={hotels.entities}
            dataTable={dataTableModel}
            sortField='name'
            sortType='ASC'
            onFilterValueChange={onFilterValueChange}
            onRefreshData={onRefresh}
            onPageChange={onPageChange}
            onSortChanged={onSortChanged}
            pagingInfo={hotels.pagingInfo}
        />
    </React.Fragment>
}
export default HotelsPage