import * as React from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../store'
import { Container } from 'traveldesk-ui'
const UnauthorizedPage = () => {
    const Localization = useSelector((state: ApplicationState) => state.localization.locale)
    return (
        <Container>
            <h2 className="checkout-title">{Localization.getString('Sign In')}</h2>
            <div className="checkout-box-wrap checkout-box-details">
                <h3>{Localization.getString('You are not allowed to access this page')}</h3>
            </div>
        </Container>
    )
}
export default UnauthorizedPage
