export const urls = {
    currencies: '/currencies',
    currencyForm: (currencyId: number) => '/currencies' + currencyId,
    countries: '/countries',
    languages: '/languages',
    countryForm: (countryId: number) => '/countries/' + countryId,
    countryTranslationForm: (countryId: number, languageId: number) => `/countries/${countryId}/details/${languageId}`,
    cities: '/cities',
    cityForm: (countryId: number, cityId: number) => `/cities/${countryId}/${cityId}`,
    cityTranslationForm: (countryId: number, cityId: number, languageId: number) =>
        `/cities/${countryId}/${cityId}/details/${languageId}`,
    toursCategories: '/tc',
    toursCategoryForm: (tourCategoryId: number) => `/tc/${tourCategoryId}`,
    users: '/users',
    inventory: '/inventory',
    bookings: '/bookings',
    crm: '/crm',
    crmB2b: '/crm/b2b',
    crmB2c: '/crm/b2c',
    accountsStatements: '/accounts/statements',
    accountsRv: '/accounts/rv',
    accountsPv: '/accounts/pv',
    accountsJv: '/accounts/jv',
    accountsSummary: '/accounts',
    login: '/account/login',
    hotels: '/hotels',
    hotelsInCountry: (countryId: number) => `/hotels/${countryId}`,
    hotelForm: (hotelId: number, countryId: number) => `/hotels/${countryId}/${hotelId > 0 ? hotelId : 'add'}`,
    promoCodes: '/marketing/promocodes',
    promoCodeForm: (id?: number) => `/marketing/promocodes/${(id ?? 0) > 0 ? id : "add"}`
}
