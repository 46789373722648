import { Photo } from 'traveldesk-ui'
import { MetaDataModelTourCategory } from './MetaDataModel'

export class TourCategoryModel {
    public static Create(obj: TourCategoryModel) {
        return Object.assign(new TourCategoryModel(), obj)
    }
    id: number = 0
    name: string = ''
    slug: string = ''
    isDeleted: boolean = false
    heroImageId?: number = 0
    photos: Photo[] = []
    metaData: MetaDataModelTourCategory[] = []
}
