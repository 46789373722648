import * as React from 'react'
import { Redirect, RouteComponentProps } from 'react-router'

import {
    Grid,
    Row,
    Col,
    SectionTitle,
    ContainerWithPadding,
    IdFormField,
    TextFormField,
    Tooltip,
    SelectFormFieldController,
    Buttons,
    Photo,
    ToggleButtonsFormField,
    ToggleFieldController,
    LabelFormField,
    SelectFormField,
    NumberFormField,
    DateFormFieldController,
} from 'traveldesk-ui'
import { useDispatch, useSelector } from 'react-redux'
import { urls } from '../../urls'
import { LoadingSection } from '../../components/LoadingSection'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { nameof } from 'traveldesk-ui'
import { ApplicationState } from '../../store'
import PromoCodeModel, { PromoCodeType, PromoCodeTypeOptions } from '../../models/Marketing/PromoCodeModel'
import PromoCodesApiService from '../../services/PromoCodesApiService'
import moment from 'moment'

interface Props extends RouteComponentProps<{ id: string }> { }
const PromoCodeFormPage = (props: Props) => {
    const dispatch = useDispatch()
    const { register, handleSubmit, formState: { errors }, control, getValues, setValue, watch, reset, setError } = useForm()
    const Localization = useSelector((state: ApplicationState) => state.localization.locale)
    const [promoCode, setPromoCode] = React.useState<PromoCodeModel>(new PromoCodeModel())
    const requestData = async () => {
        const idUrl = props.match.params.id
        if (idUrl == 'add') {
            setPromoCode(new PromoCodeModel())
        }
        else {
            const id = parseInt(idUrl)
            const promoCode = await PromoCodesApiService.get(id, dispatch)
            if (promoCode.IsOk && promoCode.Content) {
                setPromoCode(PromoCodeModel.Create(promoCode.Content))
            }
            else {
                alert("Couldn't get promocode: " + promoCode.ErrorMessage)
            }
        }
    }
    React.useEffect(() => {
        requestData()
    }, [props.match.params])

    React.useEffect(() => {
        reset(promoCode)
    }, [promoCode])
    const onSubmit = async (data: any) => {
        const response = await PromoCodesApiService.save(data, dispatch)
        if (response.IsOk) {
            props.history.push(urls.promoCodes)
        }
        else {
            alert(response.ErrorMessage)
        }
    }
    return (
        <ContainerWithPadding>

            <Grid>
                <SectionTitle
                    title={
                        promoCode.id > 0
                            ? `${Localization.getString('Edit promocode')} ${promoCode.code}`
                            : Localization.getString('Add new promo code')
                    }
                />
                <Link className="a-back-to-list" to={urls.promoCodes}>
                    {Localization.getString('Back to promo codes list')}
                </Link>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Col type="50">
                            <IdFormField control={control} />
                            <TextFormField
                                label={Localization.getString('Code Name')}
                                placeholder={Localization.getString('Name of the promocode')}
                                isRequired
                                error={errors.name ? errors.name.message : ''}
                                // defaultValue={tour.nameInternal}
                                name={nameof<PromoCodeModel>('name')}
                                register={register}
                                options={{
                                    required: Localization.getString('Promo code name is required'),
                                }}
                            />
                            <TextFormField
                                label={Localization.getString('Code ')}
                                placeholder={Localization.getString('Promo code itself')}
                                isRequired
                                error={errors.url ? errors.url.message : ''}
                                // defaultValue={tour.nameInternal}
                                name={nameof<PromoCodeModel>('code')}
                                register={register}
                                options={{
                                    required: Localization.getString('Promo code is required'),
                                }}
                            />
                            <NumberFormField
                                label={Localization.getString('Discount')}
                                placeholder={Localization.getString('Discount %')}
                                isRequired
                                error={errors.discount ? errors.discount.message : ''}
                                // defaultValue={tour.nameInternal}
                                name={nameof<PromoCodeModel>('discount')}
                                register={register}
                                min={1}
                                options={{
                                    required: Localization.getString('Discount code is required'),
                                }}
                            />
                            <SelectFormFieldController
                                control={control}
                                name={nameof<PromoCodeModel>('type')}
                                label={Localization.getString('Type')}
                                options={PromoCodeTypeOptions}
                            // isNotSearchable={true}
                            />
                            <ToggleFieldController
                                name={nameof<PromoCodeModel>("isApplicableWithDiscount")}
                                label={Localization.getString("Is Applicable With Discount")}
                                control={control}
                            />
                        </Col>
                        <Col type="50">
                            <DateFormFieldController
                                tabIndex={0}
                                disabledDays={{ before: moment().toDate() }}
                                name={nameof<PromoCodeModel>('startDateTime')}
                                label={Localization.getString('Start Date')}
                                isRequired
                                control={control}
                            />
                            <DateFormFieldController
                                tabIndex={0}
                                disabledDays={{ before: moment().toDate() }}
                                name={nameof<PromoCodeModel>('endDateTime')}
                                label={Localization.getString('End Date')}
                                isRequired
                                control={control}
                            />
                            <NumberFormField
                                min={0}
                                label={Localization.getString('Min. price')}
                                register={register}
                                defaultValue={promoCode.minPrice}
                                name={nameof<PromoCodeModel>("minPrice")}
                            />
                            <ToggleFieldController
                                name={nameof<PromoCodeModel>("isActive")}
                                label={Localization.getString("Is Active")}
                                control={control}
                            />
                            <ToggleFieldController
                                name={nameof<PromoCodeModel>("isPublished")}
                                label={Localization.getString("Is Published B2C")}
                                control={control}
                            />
                        </Col>
                    </Row>


                    <Buttons.StickySaveButton>{Localization.getString('Save')}</Buttons.StickySaveButton>
                </form>
            </Grid>
        </ContainerWithPadding>
    )
}
export default PromoCodeFormPage
