import moment from 'moment'
export default class InventoryModel {
    public static Create(obj: InventoryModel) {
        return Object.assign(new InventoryModel(), obj, {
            periodStart: moment(obj.periodStart),
            periodEnd: moment(obj.periodEnd),
        })
    }
    id: number = 0
    periodStart: moment.Moment = moment()
    periodEnd: moment.Moment = moment()
    pricePerAdultRecUsd: number = 0
    pricePerChildRecUsd: number = 0
    pricePerTripRecUsd: number = 0
    commission: number = 0
    netPricePerAdult?: number
    netPricePerChild?: number
    netPricePerTrip?: number
    isPublishedB2C: boolean = false
    pricePerAdultUsdB2C?: number = undefined
    pricePerChildUsdB2C?: number = undefined
    pricePerTripUsdB2C?: number = undefined
    supplierId: number = 0
    languageId: number = 0
    cities: number[] = []
    countries: number[] = []
    tourId: number = 0
    tourUrl: string = ''
    tourName: string = ''
    offerId: number = 0
    offerName: string = ''
    isLiveBook: boolean = false
    discountPercent?: number
    discountFrom?: moment.Moment
    discountTo?: moment.Moment
}
