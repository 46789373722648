import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ApplicationState } from '../store'
import { DataTable, DataTableColumnModel, DataTableModel, FilterType } from 'traveldesk-ui'
import { actionCreators as languagesActions } from '../store/Languages'
import { actionCreators as translationsActions } from '../store/Translations'
import LanguageModel from '../models/LanguageModel'

const LanguagesPage = () => {
    const dispatch = useDispatch()
    const Localization = useSelector((state: ApplicationState) => state.localization.locale)
    const languages = useSelector((state: ApplicationState) => state.languages.data)
    const translations = useSelector((state: ApplicationState) => state.translations.data)
    const getData = () => {
        dispatch(translationsActions.requestData())
        dispatch(languagesActions.requestData())
    }
    const onRefresh = () => {
        getData()
    }
    const initDataTable = (languages: LanguageModel[]) => {
        const columns = [
            new DataTableColumnModel({
                fieldName: 'text',
                displayName: 'Code',
                sortable: true,
                filterType: FilterType.TEXT,
            }),
            ...languages
                .filter((x) => x.isOffers)
                .map(
                    (l) =>
                        new DataTableColumnModel({
                            fieldName: l.id.toString(),
                            displayName: l.name,
                        }),
                ),
        ]

        return new DataTableModel({
            name: Localization.getString('Translations'),
            columns,
        })
    }
    const dataTableModel = React.useMemo(() => initDataTable(languages), [languages])
    return <DataTable data={translations} dataTable={dataTableModel} onRefreshData={onRefresh} />
}
export default LanguagesPage
