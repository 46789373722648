import * as Counter from './Counter'
import * as Global from './Global'
import * as Cities from './Cities'
import * as Countries from './Countries'
import * as Inventory from './Inventory'
import * as Currencies from './Currencies'
import * as Languages from './Languages'
import * as Translations from './Translations'
import * as ToursCategories from './ToursCategories'
import * as Hotels from './Hotels'

import { AuthStore, LoadingStore, Localization, MediaStore } from 'traveldesk-ui'

// The top-level state object
export interface ApplicationState {
    inventory: Inventory.State
    auth: AuthStore.State
    global: Global.State
    counter: Counter.CounterState | undefined
    photos: MediaStore.State
    loading: LoadingStore.State
    cities: Cities.State
    hotels: Hotels.State
    toursCategories: ToursCategories.State
    currencies: Currencies.State
    countries: Countries.State
    languages: Languages.State
    translations: Translations.State
    localization: Localization.State
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState import { AuthStore, LoadingStore } from 'traveldesk-ui';
export const reducers = {
    languages: Languages.reducer,
    photos: MediaStore.reducer,
    translations: Translations.reducer,
    inventory: Inventory.reducer,
    counter: Counter.reducer,
    cities: Cities.reducer,
    localization: Localization.reducer,
    countries: Countries.reducer,
    auth: AuthStore.reducer,
    loading: LoadingStore.reducer,
    global: Global.reducer,
    currencies: Currencies.reducer,
    toursCategories: ToursCategories.reducer,
    hotels: Hotels.reducer
}

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void
}
