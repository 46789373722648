export default class HotelModel {
    id: number = 0
    name: string = ''
    nameUrl: string = ''
    stars?: number = undefined
    countryId: number = 0
    countryName: string = ''
    cityId: number = 0
    cityName: string = ''
    regionId: number = 0
    regionName: string = ''
    address: string = ''
    phone: string = ''
    email: string = ''
    latitude?: number
    longitude?: number
} 