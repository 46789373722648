import * as React from 'react'
import { Redirect, RouteComponentProps } from 'react-router'
import { actionCreators as currenciesActions } from '../store/Currencies'
import { LoadingSection } from '../components/LoadingSection'

import { Grid, Row, Col, SectionTitle, ContainerWithPadding, IdFormField, TextFormField, Buttons } from 'traveldesk-ui'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../store'
import CountryModel from '../models/CountryModel'
import { urls } from '../urls'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { nameof } from 'traveldesk-ui'

import CurrencyModel from '../models/CurrencyModel'

interface Props extends RouteComponentProps<{ id: string }> { }
const CurrencyFormPage = (props: Props) => {
    const dispatch = useDispatch()
    const { register, handleSubmit, formState: { errors }, control, getValues, setValue, watch, reset, setError } = useForm()
    const Localization = useSelector((state: ApplicationState) => state.localization.locale)
    const currencies = useSelector((state: ApplicationState) => state.currencies.data)
    const model = CurrencyModel.Create(watch() as any)
    const currency: CurrencyModel | undefined = React.useMemo(() => {
        const id = parseInt(props.match.params.id)
        if (id == 0) {
            return currency || new CurrencyModel()
        }
        return currencies.find((x) => x.id == id)
    }, [props.match.params.id, currencies])
    React.useEffect(() => {
        if (currencies.length == 0) {
            dispatch(currenciesActions.requestData())
        }
    }, [])

    React.useEffect(() => {
        reset(currency)
    }, [currency])
    const onSubmit = (data: any) => {
        dispatch(currenciesActions.saveData(data))
    }

    return (
        <ContainerWithPadding>
            {currency ? (
                <Grid>
                    <SectionTitle
                        title={
                            currency.id > 0
                                ? `${Localization.getString('Edit Currency')} ${currency.name}`
                                : Localization.getString('Add Currency')
                        }
                    />
                    <Link className="a-back-to-list" to={urls.currencies}>
                        {Localization.getString('Back to currencies list')}
                    </Link>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col type="50">
                                <IdFormField control={control} />
                                <TextFormField
                                    label={Localization.getString('Currency Code')}
                                    placeholder={'USD'}
                                    isRequired
                                    error={errors.code ? errors.code.message : ''}
                                    register={register}
                                    // defaultValue={tour.nameInternal}
                                    name={nameof<CurrencyModel>('code')}
                                    options={{
                                        required: Localization.getString('Currency code is required'),
                                    }}
                                />
                                <TextFormField
                                    label={Localization.getString('Currency Name')}
                                    register={register}
                                    placeholder={'United States Dollar'}
                                    // subLabel={Localization.getString('Internal name of your tour')}
                                    isRequired
                                    error={errors.name ? errors.name.message : ''}
                                    // defaultValue={tour.nameInternal}
                                    name={nameof<CountryModel>('name')}
                                    options={{
                                        required: Localization.getString('Currency name is required'),
                                    }}
                                />
                            </Col>
                            <Col type="50">
                                <TextFormField
                                    label={Localization.getString('Html Code')}
                                    placeholder={''}
                                    isRequired
                                    register={register}
                                    // defaultValue={tour.nameInternal}
                                    name={nameof<CurrencyModel>('htmlCode')}
                                />
                                {<span>Preview: {model.renderHtmlCode}</span>}
                            </Col>
                        </Row>
                        <Buttons.StickySaveButton>{Localization.getString('Save')}</Buttons.StickySaveButton>
                    </form>
                </Grid>
            ) : currencies.length == 0 ? (
                <LoadingSection />
            ) : (
                <Redirect to={urls.currencies} />
            )}
        </ContainerWithPadding>
    )
}
export default CurrencyFormPage
