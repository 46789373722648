import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { useForm } from 'react-hook-form'
import {
    Grid,
    Row,
    Col,
    SectionTitle,
    ContainerWithPadding,
    Photo,
    Tooltip,
    IdFormField,
    TextFormField,
    nameof,
    MediaSelector,
    Buttons,
    ToggleFormField,
    ToggleFieldController,
} from 'traveldesk-ui'
import { ApplicationState } from '../store'
import { actionCreators as toursCategoriesActions } from '../store/ToursCategories'
import { actionCreators as languagesActions } from '../store/Languages'
import { TourCategoryModel } from '../models/TourCategoryModel'
import CountryModel from '../models/CountryModel'
import { urls } from '../urls'
import { Link } from 'react-router-dom'
import { DataTable, DataTableColumnModel, DataTableModel, DataTableModelCustomAction, FilterType } from 'traveldesk-ui'
import { MetaDataModelTourCategory } from '../models/MetaDataModel'
import TourCategoryTranslationFormPopup from './TourCategoryTranslationFormPopup'

interface Props extends RouteComponentProps<{ tourCategoryId: string }> { }
const TourCategoryFormPage = (props: Props) => {
    const dispatch = useDispatch()
    const Localization = useSelector((state: ApplicationState) => state.localization.locale)
    const languages = useSelector((state: ApplicationState) => state.languages.data)
    const toursCategories = useSelector((state: ApplicationState) => state.toursCategories.manageList)
    const { register, handleSubmit, formState: { errors }, control, getValues, setValue, watch, reset, setError } = useForm()
    React.useEffect(() => {
        if (languages.length == 0) dispatch(languagesActions.requestData())
    }, [])
    const tc = useSelector((state: ApplicationState) => state.toursCategories.edit)
    React.useEffect(() => {
        return () => {
            dispatch(toursCategoriesActions.edit(undefined))
        }
    }, [])
    React.useEffect(() => {
        if (languages.length == 0) {
            dispatch(languagesActions.requestData())
        }
        if (toursCategories.length == 0) {
            dispatch(toursCategoriesActions.requestManageList())
        }
    }, [])
    React.useEffect(() => {
        const id = parseInt(props.match.params.tourCategoryId)
        if (toursCategories.length > 0) {
            const c = toursCategories.find((x) => x.id == id) || new TourCategoryModel()
            if (c.id != id) {
                c.id = -1
            }
            dispatch(toursCategoriesActions.edit(c))
        }
    }, [toursCategories, props.match.params.tourCategoryId])

    React.useEffect(() => {
        setPhotos(tc?.photos ?? [])
        setHeroImageId(tc?.heroImageId)
        reset(tc)
    }, [tc?.id || -1])
    const [heroImageId, setHeroImageId] = React.useState(tc?.heroImageId)
    const onHeroImageChanged = (photo: Photo) => {
        setHeroImageId(photo.id)
    }
    const [showAllLanguages, setShowAllLanguages] = React.useState(false)
    const [photos, setPhotos] = React.useState(tc?.photos ?? [])
    const onSelectPhotos = (newPhotos: Photo[]) => {
        setPhotos(photos.concat(newPhotos))
    }
    const onSubmit = (data: any) => {
        const model = Object.assign(TourCategoryModel.Create(data), {
            metaData: tc?.metaData ?? [],
            heroImageId,
            photos,
        })
        dispatch(toursCategoriesActions.saveData(model))
    }
    const initTranslationsTable = () => {
        const columns = [
            new DataTableColumnModel({
                fieldName: 'languageName',
                displayName: Localization.getString('Language'),
                sortable: true,
                filterType: FilterType.TEXT,
            }),
            new DataTableColumnModel({
                fieldName: 'name',
                displayName: Localization.getString('Tour Category Name'),
                sortable: true,
                filterType: FilterType.TEXT,
            }),
            new DataTableColumnModel({
                fieldName: 'pageTitle',
                displayName: Localization.getString('Page Title'),
                // sortable: true,
                filterType: FilterType.TEXT,
            }),
            new DataTableColumnModel({
                fieldName: 'shortDescription',
                displayName: Localization.getString('Short Description'),
                filterType: FilterType.TEXT,
                isEditable: true,
                customRenderer: (obj: MetaDataModelTourCategory) => (
                    <Tooltip text={obj.shortDescription}>
                        {(obj.shortDescription && obj.shortDescription.length > 100)
                            ? obj.shortDescription.substring(0, 100) + '...'
                            : obj.shortDescription || ''}
                    </Tooltip>
                ),
            }),
            new DataTableColumnModel({
                fieldName: 'description',
                displayName: Localization.getString('Description'),
                filterType: FilterType.TEXT,
                isEditable: true,
                customRenderer: (obj: MetaDataModelTourCategory) => (
                    <Tooltip text={obj.description}>
                        {(obj.description && obj.description.length > 100)
                            ? obj.description.substring(0, 100) + '...'
                            : obj.description || ''}
                    </Tooltip>
                ),
            }),
            new DataTableColumnModel({
                fieldName: '',
                displayName: '',
                customRenderer: (obj: MetaDataModelTourCategory) => (
                    <i onClick={() => setEditTranslation(obj)} className="fa fa-pencil clickable" />
                ),
            }),
        ]
        return new DataTableModel({
            name: Localization.getString('Translations'),
            columns,
            customActions: [
                new DataTableModelCustomAction(
                    () => { },
                    () => (
                        <Tooltip
                            style={{ width: 200 }}
                            text={
                                showAllLanguages
                                    ? Localization.getString('Show all languages')
                                    : Localization.getString('Show only languages with tours')
                            }
                        >
                            <i
                                onClick={() => setShowAllLanguages(!showAllLanguages)}
                                className={`fa fa-eye ${showAllLanguages ? 'active' : ''}`}
                            />
                        </Tooltip>
                    ),
                ),
            ],
        })
    }
    const dataTableTranslationsModel = React.useMemo(() => initTranslationsTable(), [
        showAllLanguages,
        Localization,
        tc,
    ])
    const translationsData = React.useMemo(() => {
        const l = showAllLanguages ? languages : languages.filter((x) => x.isOffers)
        if (tc) {
            const res = l.map((x) =>
                Object.assign(
                    tc.metaData.find((m) => m.languageId == x.id) ?? MetaDataModelTourCategory.FromLanguage(x, tc.id),
                    {
                        languageName: x.name,
                    },
                ),
            )
            return res
        }
        return []
    }, [languages, tc, showAllLanguages])
    const [editTranslation, setEditTranslation] = React.useState(undefined as MetaDataModelTourCategory | undefined)
    const onCancelEditTranslation = () => {
        setEditTranslation(undefined)
    }
    const onSubmitEditTranslation = (obj: MetaDataModelTourCategory) => {
        if (tc) {
            const model = Object.assign(new MetaDataModelTourCategory(), obj, { tourCategoryId: tc.id })
            dispatch(toursCategoriesActions.saveTranslation(tc?.id, model))
        }
        setEditTranslation(undefined)
    }
    return (
        <ContainerWithPadding>
            {editTranslation && (
                <TourCategoryTranslationFormPopup
                    tourCategoryTranslation={editTranslation}
                    onClose={onCancelEditTranslation}
                    onSave={onSubmitEditTranslation}
                />
            )}
            {tc ? (
                <Grid>
                    {' '}
                    <SectionTitle
                        title={
                            tc.id > 0
                                ? `${Localization.getString('Edit Tour Category')} ${tc.name}`
                                : `${Localization.getString('Add Tour Category')}`
                        }
                    />
                    <Link className="a-back-to-list" to={urls.toursCategories}>
                        {Localization.getString(`Back to Tours Categories`)}
                    </Link>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col type="50">
                                <IdFormField control={control} />
                                <TextFormField
                                    label={Localization.getString('Tour Category Name')}
                                    placeholder={Localization.getString('Name of tour category')}
                                    isRequired
                                    error={errors.name ? errors.name.message : ''}
                                    name={nameof<TourCategoryModel>('name')}
                                    register={register}
                                    options={{
                                        required: Localization.getString('Tour category name is required'),
                                    }}
                                />
                                <TextFormField
                                    label={Localization.getString('Slug')}
                                    placeholder={Localization.getString('Url of the tour category')}
                                    isRequired
                                    error={errors.url ? errors.url.message : ''}
                                    name={nameof<TourCategoryModel>('slug')}
                                    register={register}
                                    options={{
                                        required: Localization.getString('Tour category slug is required'),
                                    }}
                                />
                            </Col>
                            <Col type="50">
                                <MediaSelector
                                    heroImageId={heroImageId}
                                    onHeroImageChanged={onHeroImageChanged}
                                    selected={photos}
                                    onSelectPhotos={onSelectPhotos}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col type="100">
                                <DataTable
                                    data={translationsData}
                                    fixedHeight={300}
                                    dataTable={dataTableTranslationsModel}
                                />
                            </Col>
                        </Row>
                        <Buttons.StickySaveButton>{Localization.getString('Save')}</Buttons.StickySaveButton>
                    </form>
                </Grid>
            ) : null}
        </ContainerWithPadding>
    )
}
export default TourCategoryFormPage
