import { apiCallWrapper } from 'traveldesk-ui'
import { AppThunkAction } from '.'
import { Action, Reducer } from 'redux'
import LanguageModel from '../models/LanguageModel'

export interface State {
    data: LanguageModel[]
    isLoading: boolean
}
const REQUEST_LANGUAGES = 'A_REQUEST_LANGUAGES'
interface RequestLanguages {
    type: 'A_REQUEST_LANGUAGES'
}
const RECEIVE_LANGUAGES = 'A_RECEIVE_LANGUAGES'
interface ReceiveLanguages {
    type: 'A_RECEIVE_LANGUAGES'
    payload: LanguageModel[]
}

type KnownAction = ReceiveLanguages | RequestLanguages

export const actionCreators = {
    requestData: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: REQUEST_LANGUAGES })
        const result = await apiCallWrapper<LanguageModel[]>('/api/languages', 'GET', dispatch)
        if (result.IsOk) {
            dispatch({ type: RECEIVE_LANGUAGES, payload: result.Content || [] })
        }
    },
}

const unloadedState: State = { data: [] as LanguageModel[], isLoading: false }
export const reducer: Reducer<State> = (state: State | undefined, incomingAction: Action): State => {
    if (state === undefined) {
        return unloadedState
    }

    const action = incomingAction as KnownAction
    switch (action.type) {
        case REQUEST_LANGUAGES:
            return {
                ...state,
                isLoading: true,
            }
        case RECEIVE_LANGUAGES:
            return {
                ...state,
                data: action.payload,
                isLoading: false,
            }
        default:
            return state
    }
}
