import LanguageModel from './LanguageModel'

class MetaDataModel {
    id: number = 0
    languageId: number = 0
    languageName: string = ''
    name: string = ''
    pageTitle: string = ''
    description: string = ''
    shortDescription: string = ''
}

export class MetaDataModelCity extends MetaDataModel {
    public static FromLanguage(l: LanguageModel, cityId: number) {
        return Object.assign(new MetaDataModelCity(), {
            languageId: l.id,
            cityId,
        })
    }
    cityId: number = 0
}
export class MetaDataModelTourCategory extends MetaDataModel {
    public static FromLanguage(l: LanguageModel, tourCategoryId: number) {
        return Object.assign(new MetaDataModelTourCategory(), {
            languageId: l.id,
            tourCategoryId,
        })
    }
    tourCategoryId: number = 0
}
export class MetaDataModelCountry extends MetaDataModel {
    public static FromLanguage(l: LanguageModel, countryId: number) {
        return Object.assign(new MetaDataModelCountry(), {
            languageId: l.id,
            countryId,
        })
    }
    countryId: number = 0
}

