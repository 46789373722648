import * as React from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from './../store/index'
import { LoadingSpinner } from 'traveldesk-ui'
const Loading = () => {
    const loadingState = useSelector((state: ApplicationState) => state.loading)
    React.useEffect(() => {
        if (loadingState.loading > 0) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'auto'
        }
    }, [loadingState])
    return loadingState.loading > 0 ? <LoadingSpinner /> : null
}
export default Loading
