import * as React from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { ApplicationState } from '../store'
// import { actionCreators as citiesActions } from '../store/Cities'
import { actionCreators as globalActions } from '../store/Global'
// import { actionCreators as languagesActions } from '../store/Languages'
import { AuthData, AuthStore } from 'traveldesk-ui'
import { Select, SelectOptionModel, useOnCloseElement } from 'traveldesk-ui'
import { HeaderMenuItemWithChildren } from 'traveldesk-ui'
import { imgPath } from '../config'
import { urls } from './../urls'
import styled from 'styled-components'
export interface MenuItem {
    title: string
    url: string
}
const Version = styled.span`
    position: absolute;
    top: 22px;
    left: 27px;
    font-size: 8px;
    color: #aaa;
`
const Header = () => {
    const [isOpened, setIsOpened] = React.useState(false)
    const [showMobileMenu, setShowMobileMenu] = React.useState(false)
    const auth = useSelector((state: ApplicationState) => state.auth)
    const path = useSelector((state: ApplicationState) => state.global.path)
    // const languages = useSelector((state: ApplicationState) => state.languages)
    const Localization = useSelector((state: ApplicationState) => state.localization.locale)
    const language = useSelector((state: ApplicationState) => state.localization.language)
    const dispatch = useDispatch()
    const close = () => {
        if (isOpened) {
            toggle()
        }
    }
    const ref = React.useRef()
    useOnCloseElement(ref, close)
    React.useEffect(() => {
        // dispatch(citiesActions.requestCitiesOptions())
        // dispatch(languagesActions.requestLanguages())
    }, [])
    React.useEffect(() => {
        close()
    }, [path])
    const toggle = () => setIsOpened(!isOpened)
    const toggleMobileMenu = () => setShowMobileMenu(!showMobileMenu)

    // const onLanguageChanged = (option: SelectOptionModel) => {
    //     const lang = languages.languagesHash.get(option.value as number)
    //     if (lang) dispatch(globalActions.setLanguage(lang.code))
    // }
    const signOut = () => {
        dispatch(AuthStore.actionCreators.signOut())
    }
    const isAuthorized = auth.isAuthorized
    const isAdmin = React.useMemo(() => {
        return auth.authData?.isInRole("admin")
    }, [auth])
    const isHotelsAdmin = React.useMemo(() => {
        return auth.authData?.isInRole("hotelsadmin")
    }, [auth])
    return (
        <header className={`traveldesk-main-header${showMobileMenu ? ' show-mobile-menu' : ''}`}>
            <div className="header-inner container">
                <div className="header-inner--left">
                    <div className="header-main-logo">
                        <Version>1.0.2</Version>
                        <Link to="/">
                            <img
                                src={`${imgPath}/logo-travel.png`}
                                alt="traveldesk admin"
                                width="216"
                                height="36"
                                title="Traveldesk"
                            />
                        </Link>
                    </div>
                    <nav ref={ref as any} className="main-navigation">
                        <a onClick={toggle} className="mobile-navigation-toggler">
                            {Localization.getString('Menu')}
                        </a>
                        <form method="post" className="header-search-form">
                            <input type="search" placeholder="Search and hit enter" />
                        </form>
                        {isAuthorized && (
                            <ul className={`main-menu${isOpened ? ' mobile-opened' : ''}`}>
                                {/* <li className="is-active">
                                    <Link to="/bookings">{Localization.getString('Bookings')}</Link>
                                </li> */}
                                <HeaderMenuItemWithChildren title={Localization.getString('Admin')}>
                                    {isAdmin ? <React.Fragment>
                                        <li>
                                            <Link to={urls.countries}>{Localization.getString('Countries')}</Link>
                                        </li>
                                        <li>
                                            <Link to={urls.languages}>{Localization.getString('Languages')}</Link>
                                        </li>
                                        <li>
                                            <Link to={urls.currencies}>{Localization.getString('Currencies')}</Link>
                                        </li>
                                        <li>
                                            <Link to={urls.toursCategories}>{Localization.getString("Tours Categories")}</Link>
                                        </li>
                                    </React.Fragment> : null}
                                    {(isAdmin || isHotelsAdmin) ? <React.Fragment>
                                        <li>
                                            <Link to={urls.hotels}>{Localization.getString('Hotels')}</Link>
                                        </li>
                                    </React.Fragment> : null}
                                    {isAdmin ? <React.Fragment>
                                        <li>
                                            <Link to={urls.users}>{Localization.getString('Users')}</Link>
                                        </li>
                                        <li>
                                            <Link to={urls.promoCodes}>{Localization.getString("Promo codes")}</Link>
                                        </li>
                                    </React.Fragment> : null}
                                </HeaderMenuItemWithChildren>
                                {isAdmin ? <React.Fragment>
                                    <li className="is-active">
                                        <Link to={urls.inventory}>{Localization.getString('Inventory')}</Link>
                                    </li>
                                    <li className="is-active">
                                        <Link to={urls.bookings}>{Localization.getString('Bookings')}</Link>
                                    </li>
                                    <HeaderMenuItemWithChildren title={Localization.getString('CRM')}>
                                        <li>
                                            <Link to={urls.crmB2b}>{Localization.getString('B2B')}</Link>
                                        </li>
                                        <li>
                                            <Link to={urls.crmB2c}>{Localization.getString('B2C')}</Link>
                                        </li>
                                    </HeaderMenuItemWithChildren>
                                    <HeaderMenuItemWithChildren title={Localization.getString('Accounts')}>
                                        <li>
                                            <Link to={urls.accountsStatements}>{Localization.getString('Statements')}</Link>
                                        </li>
                                        <li>
                                            <Link to={urls.accountsRv}>{Localization.getString('RV')}</Link>
                                        </li>
                                        <li>
                                            <Link to={urls.accountsPv}>{Localization.getString('PV')}</Link>
                                        </li>
                                        <li>
                                            <Link to={urls.accountsJv}>{Localization.getString('JV')}</Link>
                                        </li>
                                        <li>
                                            <Link to={urls.accountsSummary}>{Localization.getString('Summary')}</Link>
                                        </li>
                                    </HeaderMenuItemWithChildren>
                                </React.Fragment> : null}
                                {/* <li>
                                <a href="#">About Us</a>
                            </li>
                            <li>
                                <a href="#">Contacts</a>
                            </li> */}
                            </ul>
                        )}
                    </nav>
                    <a onClick={toggleMobileMenu} className="header-mobile-toggler">
                        <span></span>
                        <span></span>
                        <span></span>
                    </a>
                </div>
                <div className="header-inner--right">
                    <div className="header-select-lang-wrap">
                        <Select
                            wrapperClassName={'header-select-lang list-active'}
                            isNotSearchable
                            options={[]} //languages.languagesOptions}
                            // onChange={onLanguageChanged}
                            value={language}
                        />
                    </div>
                    <div className="header-select-currency-wrap">
                        <div className="header-select-currency">
                            <span className="header-currency-label">USD</span>
                            <input type="hidden" name="header-currency-value" />
                            <ul className="header-currency-list">
                                <li data-value="usd">USD</li>
                                <li data-value="gbp">GBP</li>
                                <li data-value="rub">RUB</li>
                            </ul>
                        </div>
                    </div>

                    {auth.isAuthorized && (
                        <div className="header-account-wrap">
                            <a href="#" className="header--account-link">
                                <i></i>
                                {Localization.getString('My Account')}
                            </a>
                            <div className="header-account-menu-wrap">
                                <ul className="header-account-menu">
                                    <li>
                                        <a>
                                            <i className="header-account-item--profile"></i>
                                            {Localization.getString('My Profile')}
                                        </a>
                                    </li>
                                    <li>
                                        <a>
                                            <i className="header-account-item--bookings"></i>
                                            {Localization.getString('My Bookings')}
                                        </a>
                                    </li>
                                    <li>
                                        <a>
                                            <i className="header-account-item--help"></i>
                                            {Localization.getString('Help')}
                                        </a>
                                    </li>
                                    <li className="clickable" onClick={signOut}>
                                        <i className="header-account-item--help" />
                                        {Localization.getString('Sign Out')}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </header>
    )
}
export default Header
