import { CityModel } from './../models/CityModel'
import { apiCallWrapper } from 'traveldesk-ui'
import { SelectOptionModel } from 'traveldesk-ui'
import { b2bPath } from '../config'
export default class CitiesApiService {
    public static async Save(country: CityModel, dispatch: any) {
        return await apiCallWrapper<CityModel>(`/api/cities`, 'POST', dispatch, country)
    }
    public static async getCitiesOptions(dispatch: any) {
        return await apiCallWrapper<SelectOptionModel>(`${b2bPath}/api/cities/options`, 'GET', dispatch)
    }
}
