import * as React from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import {
    Grid,
    Row,
    Col,
    SectionTitle,
    ContainerWithPadding,
    Photo,
    Tooltip,
    IdFormField,
    TextFormField,
    nameof,
    MediaSelector,
    Buttons,
    ToggleFormField,
    ToggleFieldController,
} from 'traveldesk-ui'
import { ApplicationState } from '../store'
import { urls } from '../urls'
import { actionCreators as languagesActions } from '../store/Languages'
import { actionCreators as citiesActions } from '../store/Cities'
import { actionCreators as countriesActions } from '../store/Countries'

import { LoadingSection } from '../components/LoadingSection'
import { Link } from 'react-router-dom'
import { CityModel } from '../models/CityModel'
import { DataTable, DataTableColumnModel, DataTableModel, DataTableModelCustomAction, FilterType } from 'traveldesk-ui'
import { MetaDataModelCity } from '../models/MetaDataModel'
import CountryModel from '../models/CountryModel'

interface Props extends RouteComponentProps<{ countryId: string; cityId: string }> { }
const CityFormPage = (props: Props) => {
    const dispatch = useDispatch()
    const { register, handleSubmit,
        formState: { errors },
        control, getValues, setValue, watch, reset, setError } = useForm()
    const Localization = useSelector((state: ApplicationState) => state.localization.locale)
    const languages = useSelector((state: ApplicationState) => state.languages.data)
    const countries = useSelector((state: ApplicationState) => state.countries.manageList)
    const [country, setCountry] = React.useState((undefined as unknown) as CountryModel)
    const city = useSelector((state: ApplicationState) => state.cities.edit)
    React.useEffect(() => {
        if (countries.length == 0) dispatch(countriesActions.requestManageList())
        if (languages.length == 0) dispatch(languagesActions.requestData())
    }, [])
    React.useEffect(() => {
        const countryId = parseInt(props.match.params.countryId)
        const cityId = parseInt(props.match.params.cityId)
        if (countries.length > 0) {
            const c = countries.find((x) => x.id == countryId)
            if (c) {
                setCountry(c)
                const city = cityId == 0 ? CityModel.CreateNew(c.id) : c?.cities.find((x) => x.id == cityId)
                if (city) {
                    dispatch(citiesActions.editCity(city))
                } else {
                    props.history.push(urls.countryForm(c.id))
                }
            } else {
                props.history.push(urls.countries)
            }
        }
    }, [countries, props.match.params.countryId, props.match.params.cityId])
    React.useEffect(() => {
        return () => {
            dispatch(citiesActions.editCity(undefined))
        }
    }, [])
    React.useEffect(() => {
        // if (countries.length == 0) {
        //     dispatch(countriesActions.requestManageList())
        // }
        if (languages.length == 0) {
            dispatch(languagesActions.requestData())
        }
    }, [])
    const [heroImageId, setHeroImageId] = React.useState(city?.heroImageId)
    const onHeroImageChanged = (photo: Photo) => {
        setHeroImageId(photo.id)
    }
    const [photos, setPhotos] = React.useState(city?.photos ?? [])
    const onRemovePhoto = (photo: Photo) => {
        setPhotos(photos.filter(x => x.id != photo.id))
    }
    const onSelectPhotos = (newPhotos: Photo[]) => {
        setPhotos(photos.concat(newPhotos))
    }
    const onSubmit = (data: any) => {
        if (city) {
            const model = Object.assign(CityModel.Create(data), {
                countryId: city.countryId,
                metaData: city.metaData,
                heroImageId,
                photos,
            })
            dispatch(citiesActions.saveData(model))
        }
    }
    const [showAllLanguages, setShowAllLanguages] = React.useState(false)
    const translationsData = React.useMemo(() => {
        const l = showAllLanguages ? languages : languages.filter((x) => x.isOffers)
        if (city) {
            const res = l.map((x) =>
                Object.assign(
                    city.metaData.find((m) => m.languageId == x.id) ?? MetaDataModelCity.FromLanguage(x, city.id),
                    {
                        languageName: x.name,
                    },
                ),
            )
            return res
        }
        return []
    }, [languages, city, showAllLanguages])
    const initTranslationsTable = () => {
        const columns = [
            new DataTableColumnModel({
                fieldName: 'languageName',
                displayName: Localization.getString('Language'),
                sortable: true,
                filterType: FilterType.TEXT,
            }),
            new DataTableColumnModel({
                fieldName: 'name',
                displayName: Localization.getString('City Name'),
                sortable: true,
                filterType: FilterType.TEXT,
            }),
            new DataTableColumnModel({
                fieldName: 'pageTitle',
                displayName: Localization.getString('Page Title'),
                // sortable: true,
                filterType: FilterType.TEXT,
            }),
            new DataTableColumnModel({
                fieldName: 'shortDescription',
                displayName: Localization.getString('Short Description'),
                filterType: FilterType.TEXT,
                customRenderer: (obj: MetaDataModelCity) => (
                    <Tooltip text={obj.shortDescription}>
                        {(obj.shortDescription && obj.shortDescription.length > 100)
                            ? obj.shortDescription.substring(0, 100) + '...'
                            : obj.shortDescription || ''}
                    </Tooltip>
                ),
            }),
            new DataTableColumnModel({
                fieldName: 'description',
                displayName: Localization.getString('Description'),
                filterType: FilterType.TEXT,
                customRenderer: (obj: MetaDataModelCity) => (
                    <Tooltip text={obj.description}>
                        {(obj.description && obj.description.length > 100)
                            ? obj.description.substring(0, 100) + '...'
                            : obj.description || ''}
                    </Tooltip>
                ),
            }),
            new DataTableColumnModel({
                fieldName: '',
                displayName: '',
                customRenderer: (obj: MetaDataModelCity) => (
                    <Link to={urls.cityTranslationForm(city?.countryId ?? 0, city?.id ?? 0, obj.languageId)}>
                        <i className="fa fa-pencil" />
                    </Link>
                ),
            }),
        ]
        return new DataTableModel({
            name: Localization.getString('Translations'),
            columns,
            customActions: [
                new DataTableModelCustomAction(
                    () => { },
                    () => (
                        <Tooltip
                            style={{ width: 200 }}
                            text={
                                showAllLanguages
                                    ? Localization.getString('Show all languages')
                                    : Localization.getString('Show only languages with tours')
                            }
                        >
                            <i
                                onClick={() => setShowAllLanguages(!showAllLanguages)}
                                className={`fa fa-eye ${showAllLanguages ? 'active' : ''}`}
                            />
                        </Tooltip>
                    ),
                ),
            ],
        })
    }
    React.useEffect(() => {
        setPhotos(city?.photos ?? [])
        setHeroImageId(city?.heroImageId)
        reset(city)
    }, [city?.id || -1])
    const dataTableTranslationsModel = React.useMemo(() => initTranslationsTable(), [
        showAllLanguages,
        Localization,
        city,
    ])
    return (
        <ContainerWithPadding>
            {city ? (
                <Grid>
                    <SectionTitle
                        title={
                            city.id > 0
                                ? `${Localization.getString('Edit City')} ${city.name}`
                                : `${Localization.getString('Add City')}`
                        }
                    />
                    <Link className="a-back-to-list" to={urls.countryForm(city.countryId)}>
                        {Localization.getString('Back to ')} {country?.name}
                    </Link>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col type="50">
                                <IdFormField control={control} />
                                <TextFormField
                                    label={Localization.getString('City Name')}
                                    placeholder={Localization.getString('Name of the city')}
                                    isRequired
                                    error={errors.name ? errors.name.message : ''}
                                    register={register}
                                    name={nameof<CityModel>('name')}
                                    options={{
                                        required: Localization.getString('City name is required'),
                                    }}
                                />
                                <TextFormField
                                    label={Localization.getString('Slug')}
                                    placeholder={Localization.getString('Url of the city')}
                                    register={register}
                                    isRequired
                                    error={errors.url ? errors.url.message : ''}
                                    name={nameof<CityModel>('url')}
                                    options={{
                                        required: Localization.getString('City slug is required'),
                                    }}
                                />
                                <ToggleFieldController
                                    control={control}
                                    label={Localization.getString('Top city')}
                                    name={nameof<CityModel>('isTopCity')}
                                />
                            </Col>
                            <Col type="50">
                                <MediaSelector
                                    heroImageId={heroImageId}
                                    onHeroImageChanged={onHeroImageChanged}
                                    selected={photos}
                                    onDelete={onRemovePhoto}
                                    onSelectPhotos={onSelectPhotos}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col type="100">
                                <DataTable
                                    data={translationsData}
                                    fixedHeight={300}
                                    dataTable={dataTableTranslationsModel}
                                />
                            </Col>
                        </Row>
                        <Buttons.StickySaveButton>{Localization.getString('Save')}</Buttons.StickySaveButton>
                    </form>
                </Grid>
            ) : (
                <LoadingSection />
            )}
        </ContainerWithPadding>
    )
}
export default CityFormPage
