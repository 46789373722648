import { EntitiesPaginatedModel, apiCallWrapper } from 'traveldesk-ui'
import PromoCodeModel from '../models/Marketing/PromoCodeModel'
export default class PromoCodesApiService {
    public static async save(model: PromoCodeModel, dispatch: any) {
        return await apiCallWrapper<PromoCodeModel>(`/api/marketing/promocodes`, 'PUT', dispatch, model)
    }
    public static async getAll(page: number, dispatch: any) {
        return await apiCallWrapper<EntitiesPaginatedModel<PromoCodeModel>>(`/api/marketing/promocodes`, 'GET', dispatch)
    }
    public static async get(id: number, dispatch: any) {
        return await apiCallWrapper<PromoCodeModel>(`/api/marketing/promocodes/${id}`, 'GET', dispatch)
    }
}