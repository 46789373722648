import { TourCategoryModel } from './TourCategoryModel'
import { Photo } from 'traveldesk-ui'
import { CityModel } from './CityModel'
import { MetaDataModelCountry } from './MetaDataModel'

export default class CountryModel {
    public static Create(obj: CountryModel) {
        return Object.assign(new CountryModel(), obj, {
        })
    }
    id: number = 0
    name: string = ''
    code: string = ''
    url: string = ''
    hasTours: boolean = false
    cities: CityModel[] = []
    metaData: MetaDataModelCountry[] = []
    currencyId?: number
    heroImageId?: number
    photos: Photo[] = []
    isTopCountry: boolean = false
}
