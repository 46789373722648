import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ApplicationState } from '../store'
import { DataTable, DataTableColumnModel, DataTableModel, FilterType } from 'traveldesk-ui'
import { actionCreators as currenciesActions } from '../store/Currencies'
import CurrencyModel from '../models/CurrencyModel'
import { Link } from 'react-router-dom'
import CountryModel from '../models/CountryModel'

const CurrenciesPage = () => {
    const dispatch = useDispatch()
    const Localization = useSelector((state: ApplicationState) => state.localization.locale)
    const currencies = useSelector((state: ApplicationState) => state.currencies.data)
    const getData = () => {
        dispatch(currenciesActions.requestData())
    }
    const onRefresh = () => {
        getData()
    }
    React.useEffect(() => {
        getData()
    }, [])
    const initDataTable = () => {
        const columns = [
            new DataTableColumnModel({
                fieldName: 'code',
                displayName: 'Code',
                sortable: true,
                filterType: FilterType.TEXT,
            }),
            new DataTableColumnModel({
                fieldName: 'name',
                displayName: 'Name',
                filterType: FilterType.TEXT,
                sortable: true,
            }),
            new DataTableColumnModel({
                fieldName: 'renderHtmlCode',
                displayName: 'HTML Code',
            }),
            new DataTableColumnModel({
                fieldName: '',
                displayName: '',
                width: 35,
                customRenderer: (obj: CountryModel) => (
                    <Link to={`/currencies/${obj.id}`}>
                        <i className="fa fa-pencil" />
                    </Link>
                ),
            }),
        ]

        return new DataTableModel({
            name: Localization.getString('Currencies'),
            columns,
        })
    }
    const dataTableModel = React.useMemo(() => initDataTable(), [])
    return (
        <DataTable
            data={currencies}
            sortField="name"
            sortType="ASC"
            dataTable={dataTableModel}
            onRefreshData={onRefresh}
        />
    )
}
export default CurrenciesPage
