import { CityModel } from './../models/CityModel'
import { defaultLabelSort } from 'traveldesk-ui'
import CountriesApiService from './../services/CountriesApiService'
import { AppThunkAction } from '.'
import { Reducer, Action } from 'redux'
import CountryModel from '../models/CountryModel'
import { MetaDataModelCountry } from '../models/MetaDataModel'
import { CallHistoryMethodAction, push } from 'connected-react-router'
import { urls } from '../urls'
import { CityTranslationSave, CityUpdated, CITY_UPDATED, CITY_TRANSLATION_SAVE } from './Cities'
import CountryOptionModel from '../models/CountryOptionModel'

export interface State {
    countriesOptions: CountryOptionModel[]
    countriesWithToursOptions: CountryOptionModel[]
    countriesHash: Map<number, string>
    manageList: CountryModel[]
    edit?: CountryModel
}
const COUNTRY_TRANSLATION_SAVE = 'A_COUNTRY_TRANSLATION_SAVE'
interface CountryTranslationSave {
    type: 'A_COUNTRY_TRANSLATION_SAVE'
    payload: MetaDataModelCountry
}
const COUNTRY_ADD = 'A_COUNTRY_ADD'
interface CountryAdd {
    type: 'A_COUNTRY_ADD'
}
const COUNTRY_EDIT = 'A_COUNTRY_EDIT'
interface CountryEdit {
    type: 'A_COUNTRY_EDIT'
    payload?: CountryModel
}
const COUNTRY_UPDATED = 'A_COUNTRY_UPDATED'
interface CountryUpdated {
    type: 'A_COUNTRY_UPDATED'
    payload: CountryModel
}
const COUNTRY_CITIES_RESPONSE = 'A_COUNTRY_CITIES_RESPONSE'
interface CountryCitiesResponse {
    type: 'A_COUNTRY_CITIES_RESPONSE'
    payload: CityModel[]
    countryId: number
}
const COUNTRIES_MANAGE_RESPONSE = 'A_COUNTRIES_MANAGE_RESPONSE'
interface CountriesManageResponse {
    type: 'A_COUNTRIES_MANAGE_RESPONSE'
    payload: CountryModel[]
}
const COUNTRIES_OPTIONS_RESPONSE = 'A_COUNTRIES_OPTIONS_RESPONSE'
interface CountriesOptionsResponse {
    type: 'A_COUNTRIES_OPTIONS_RESPONSE'
    payload: CountryOptionModel[]
}

type KnownAction =
    | CountriesOptionsResponse
    | CountriesManageResponse
    | CountryCitiesResponse
    | CountryUpdated
    | CountryTranslationSave
    | CityTranslationSave
    | CountryEdit
    | CallHistoryMethodAction
    | CityUpdated

export const actionCreators = {
    saveData: (country: CountryModel): AppThunkAction<KnownAction> => async (dispatch) => {
        let fetchTask = await CountriesApiService.Save(country, dispatch)
        if (fetchTask.IsOk && fetchTask.Content) {
            dispatch({
                type: COUNTRY_UPDATED,
                payload: fetchTask.Content,
            })
            dispatch(push(urls.countryForm(fetchTask.Content.id)))
        }
    },

    editCountry: (country?: CountryModel) => ({
        type: COUNTRY_EDIT,
        payload: country,
    }),
    saveTranslation: (countryId: number, metaData: MetaDataModelCountry): AppThunkAction<KnownAction> => async (
        dispatch,
    ) => {
        dispatch({ type: COUNTRY_TRANSLATION_SAVE, payload: metaData } as CountryTranslationSave)
        dispatch(push(urls.countryForm(countryId)))
    },
    requestCountryCities: (countryId: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let fetchTask = await CountriesApiService.getCities(countryId, dispatch)
        if (fetchTask.IsOk && fetchTask.Content) {
            if (
                fetchTask.Content.length != 0 ||
                getState().countries.manageList.find((x) => x.id == countryId)?.cities.length != 0
            )
                dispatch({
                    type: COUNTRY_CITIES_RESPONSE,
                    payload: fetchTask.Content,
                    countryId,
                })
        }
    },
    requestCountriesOptions: (): AppThunkAction<KnownAction> => async (dispatch) => {
        let fetchTask = await CountriesApiService.getOptions(dispatch)
        if (fetchTask.IsOk && fetchTask.Content) {
            dispatch({
                type: COUNTRIES_OPTIONS_RESPONSE,
                payload: fetchTask.Content,
            })
        }
    },
    requestManageList: (): AppThunkAction<KnownAction> => async (dispatch) => {
        let fetchTask = await CountriesApiService.getManageList(dispatch)
        if (fetchTask.IsOk && fetchTask.Content) {
            dispatch({
                type: COUNTRIES_MANAGE_RESPONSE,
                payload: fetchTask.Content,
            })
        }
    },
}

const unloadedState: State = {
    countriesOptions: [] as CountryOptionModel[],
    countriesWithToursOptions: [] as CountryOptionModel[],
    manageList: [] as CountryModel[],
    countriesHash: new Map<number, string>(),
    edit: undefined as CountryModel | undefined,
}

export const reducer: Reducer<State> = (state: State = unloadedState, incomingAction: Action) => {
    const action = incomingAction as KnownAction
    switch (action.type) {
        case COUNTRY_EDIT:
            return {
                ...state,
                edit: action.payload,
            }

        case COUNTRY_TRANSLATION_SAVE:
            if (state.edit) {
                const metaData = state.edit.metaData.some((x) => x.languageId == action.payload.languageId)
                    ? state.edit.metaData.map((x) => (x.languageId == action.payload.languageId ? action.payload : x))
                    : state.edit.metaData.concat([action.payload])
                const edit = Object.assign(CountryModel.Create(state.edit), { metaData })
                return {
                    ...state,
                    edit,
                }
            }
            return state

        case COUNTRY_UPDATED:
            const c = action.payload
            const newOption = { value: c.id, label: c.name, hasTours: c.hasTours }
            const countriesOptions = state.countriesOptions.some((option) => option.value == c.id)
                ? state.countriesOptions.map((x) => (x.value == c.id ? newOption : x))
                : state.countriesOptions.concat([newOption]).sort(defaultLabelSort)
            const countriesHash = Object.assign(new Map<number, string>(), state.countriesHash)
            countriesHash.set(c.id, c.name)
            const manageList = state.manageList.some((x) => x.id == c.id)
                ? state.manageList.map((x) => (x.id == c.id ? CountryModel.Create(c) : x))
                : state.manageList.concat([CountryModel.Create(c)])
            return {
                ...state,
                countriesOptions,
                countriesHash,
                manageList,
            }
        case COUNTRIES_OPTIONS_RESPONSE:
            return {
                ...state,
                countriesOptions: action.payload,
                countriesHash: action.payload.reduce((res, next) => {
                    res.set(next.value as number, next.label)
                    return res
                }, new Map<number, string>()),
                countriesWithToursOptions: action.payload.filter(x => x.hasTours)
            }
        case COUNTRY_CITIES_RESPONSE:
            return {
                ...state,
                manageList: state.manageList.map((x) =>
                    x.id == action.countryId
                        ? Object.assign(CountryModel.Create(x), {
                            cities: action.payload.map((city) => CityModel.Create(city)),
                        })
                        : x,
                ),
            }
        case CITY_UPDATED:
            const cityNew = CityModel.Create(action.payload)
            const manageListUpdated = state.manageList.map((x) =>
                x.id == cityNew.countryId
                    ? Object.assign(CountryModel.Create(x), {
                        cities: x.cities.some((c) => c.id == cityNew.id)
                            ? x.cities.map((city) => (city.id == cityNew.id ? CityModel.Create(cityNew) : city))
                            : x.cities.concat([cityNew]),
                    })
                    : x,
            )
            return {
                ...state,
                manageList: manageListUpdated,
            }
        case COUNTRIES_MANAGE_RESPONSE:
            return {
                ...state,
                manageList: action.payload,
            }
    }
    return state || unloadedState
}
