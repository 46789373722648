import { Photo } from 'traveldesk-ui'
import { MetaDataModelCity } from './MetaDataModel'

export class CityModel {
    public static Create(obj: CityModel) {
        return Object.assign(new CityModel(), obj)
    }
    public static CreateNew(countryId: number) {
        return Object.assign(new CityModel(), {
            countryId,
        })
    }
    id: number = 0
    name: string = ''
    url: string = ''
    countryId: number = 0
    metaData: MetaDataModelCity[] = []
    heroImageId?: number
    photos: Photo[] = []
    isTopCity: boolean = false
}
