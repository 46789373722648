import moment from "moment"

export default class PromoCodeModel {
    public static Create(obj: PromoCodeModel) {
        return Object.assign(new PromoCodeModel(), obj, {
            startDateTime: obj.startDateTime ? moment(obj.startDateTime) : undefined,
            endDateTime: obj.endDateTime ? moment(obj.endDateTime) : undefined
        })
    }
    id: number = 0
    name: string = ''
    code: string = ''
    startDateTime?: moment.Moment
    endDateTime?: moment.Moment
    type: PromoCodeType = PromoCodeType.Percent
    minPrice?: number
    isActive: boolean = true
    isPublished: boolean = false
    discount: number = 0
    isApplicableWithDiscount: boolean = false
}
export enum PromoCodeType {
    Fixed, Percent
}
export const PromoCodeTypeOptions = [
    { label: "Fixed", value: 0 },
    { label: "Percent", value: 1 }
]