import * as React from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { Col, Grid, IdFormField, Popup, PopupFormWrapper, Row, TextAreaField, TextFormField } from 'traveldesk-ui'
import { urls } from '../urls'
import { ApplicationState } from '../store'
import { MetaDataModelCountry } from '../models/MetaDataModel'
import { nameof } from 'traveldesk-ui'
import { Buttons } from 'traveldesk-ui'
import { actionCreators as countriesActions } from '../store/Countries'

interface Props extends RouteComponentProps<{ id: string; language: string }> { }
const CountryTranslationFormPage = (props: Props) => {
    const dispatch = useDispatch()
    const countryId = parseInt(props.match.params.id)
    const languages = useSelector((state: ApplicationState) => state.languages.data)
    const country = useSelector((state: ApplicationState) => state.countries.edit)
    const language = React.useMemo(() => {
        return languages.find((x) => x.id == parseInt(props.match.params.language))
    }, [languages, props.match.params.language])
    const Localization = useSelector((state: ApplicationState) => state.localization.locale)
    const { handleSubmit, formState: { errors }, control, register, getValues, setValue, watch, reset, setError } = useForm()
    const onSubmit = (data: any) => {
        if (country) {
            const model = Object.assign(new MetaDataModelCountry(), data, { countryId: country.id })
            dispatch(countriesActions.saveTranslation(country?.id, model))
        }
    }
    React.useEffect(() => {
        const metaData =
            country?.metaData.find((x) => x.languageId == language?.id) ||
            Object.assign(new MetaDataModelCountry(), { languageId: language?.id })
        reset(metaData)
    }, [country, language])
    return country && language ? (
        <Popup onClose={() => props.history.push(urls.countryForm(countryId))}>
            <PopupFormWrapper>
                <Grid>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col type="100">
                                <h3>{`${Localization.getString('Manage')} ${country.name} ${Localization.getString(
                                    'meta data in',
                                )} ${language.name}`}</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col type="50">
                                <IdFormField name="languageId" control={control} />
                                <TextFormField
                                    label={Localization.getString('Country name')}
                                    placeholder={Localization.getString('United Arab Emirates')}
                                    subLabel={`${Localization.getString('Country name in')} ${language.name}`}
                                    isRequired
                                    error={errors.name ? errors.name.message : ''}
                                    // defaultValue={tour.nameInternal}
                                    name={nameof<MetaDataModelCountry>('name')}
                                    register={register}
                                    options={{
                                        required: Localization.getString('Country name is required'),
                                    }}
                                />
                                <TextFormField
                                    label={Localization.getString('Page title')}
                                    // placeholder={Localization.getString('United Arab Emirates')}
                                    subLabel={`${Localization.getString('Country page title in')} ${language.name}`}
                                    isRequired
                                    error={errors.pageTitle ? errors.pageTitle.message : ''}
                                    // defaultValue={tour.nameInternal}
                                    name={nameof<MetaDataModelCountry>('pageTitle')}
                                    register={register}
                                    options={{
                                        required: Localization.getString('Page title is required'),
                                    }}
                                />
                            </Col>
                            <Col type="50">
                                <TextAreaField
                                    label={Localization.getString('Short Description')}
                                    subLabel={`${Localization.getString('Country short description in')} ${language.name
                                        }`}
                                    name={nameof<MetaDataModelCountry>('shortDescription')}
                                    register={register}
                                />
                                <TextAreaField
                                    label={Localization.getString('Description')}
                                    subLabel={`${Localization.getString('Country long description in')} ${language.name
                                        }`}
                                    name={nameof<MetaDataModelCountry>('description')}
                                    register={register}
                                />
                            </Col>
                        </Row>
                        <Buttons.StickySaveButton>{Localization.getString('Save')}</Buttons.StickySaveButton>
                    </form>
                </Grid>
            </PopupFormWrapper>
        </Popup>
    ) : null
}
export default CountryTranslationFormPage
