import * as React from 'react'
export default class CurrencyModel {
    public static Create(obj: CurrencyModel) {
        return Object.assign(new CurrencyModel(), obj)
    }
    id: number = 0
    code: string = ''
    name: string = ''
    htmlCode: string = ''
    get renderHtmlCode() {
        return <span dangerouslySetInnerHTML={{ __html: this.htmlCode }}>{}</span>
    }
}
