import { EntitiesPaginatedModel } from "traveldesk-ui";
import { AppThunkAction } from ".";
import HotelModel from "../models/HotelModel";
import HotelsApiService from "../services/HotelsApiService";
import HotelsFilter from "../viewModels/HotelsFilter";
import { Action, Reducer } from "redux";
import { CallHistoryMethodAction, push } from "connected-react-router";
import { urls } from "../urls";

export interface State {
    hotels: EntitiesPaginatedModel<HotelModel>
}

const RECEIVE_HOTELS = "A_RECEIVE_HOTELS"
interface ReceiveHotels {
    type: "A_RECEIVE_HOTELS",
    payload: EntitiesPaginatedModel<HotelModel>
}

type KnownAction = | ReceiveHotels | CallHistoryMethodAction

export const actionCreators = {
    requestData: (filter: HotelsFilter): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const result = await HotelsApiService.getHotels(filter, dispatch)
        if (result.IsOk) {
            dispatch({ type: RECEIVE_HOTELS, payload: result.Content || new EntitiesPaginatedModel<HotelModel>() })
        }
    },
    save: (model: HotelModel): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const result = await HotelsApiService.save(model, dispatch)
        if (result.IsOk && result.Content) {
            dispatch(push(urls.hotelsInCountry(result.Content.countryId)))
        }
        else {
            alert(result.ErrorMessage)
        }
    }
}
const unloadedState: State = { hotels: new EntitiesPaginatedModel<HotelModel>() }

export const reducer: Reducer<State> = (state: State | undefined, incomingAction: Action): State => {
    if (state === undefined) {
        return unloadedState
    }

    const action = incomingAction as KnownAction
    switch (action.type) {
        case RECEIVE_HOTELS:
            return {
                ...state,
                hotels: action.payload
            }
        default:
            return state
    }
}

