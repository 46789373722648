import * as React from 'react'
import { DataTable, DataTableColumnModel, DataTableModel, DataTableModelCustomAction, EntitiesPaginatedModel, PaginationModel, nameof } from 'traveldesk-ui'
import PromoCodeModel, { PromoCodeTypeOptions } from '../../models/Marketing/PromoCodeModel'
import PromoCodesApiService from '../../services/PromoCodesApiService'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { urls } from '../../urls'
interface Props {

}
const PromoCodes = (props: Props) => {
    const dispatch = useDispatch()
    const [promoCodes, setPromoCodes] = React.useState<EntitiesPaginatedModel<PromoCodeModel>>(new EntitiesPaginatedModel<PromoCodeModel>())
    const [page, setPage] = React.useState<number>(1)
    const requestData = async () => {
        const response = await PromoCodesApiService.getAll(page, dispatch)
        if (response.IsOk && response.Content) {
            setPromoCodes(Object.assign(new EntitiesPaginatedModel<PromoCodeModel>(), {
                entities: response.Content.entities.map(x => PromoCodeModel.Create(x)),
                pagingInfo: PaginationModel.Create(response.Content.pagingInfo)
            }))
        }
        else {
            alert("Couldn't get promo codes: " + response.ErrorMessage)
        }
    }
    React.useEffect(() => {
        requestData()
    }, [page])
    const initTable = () => {
        const columns = [
            new DataTableColumnModel({
                fieldName: nameof<PromoCodeModel>("name"),
                displayName: "Name"
            }),
            new DataTableColumnModel({
                fieldName: nameof<PromoCodeModel>("code"),
                displayName: "Code"
            }),
            new DataTableColumnModel({
                fieldName: nameof<PromoCodeModel>("discount"),
                displayName: "Discount"
            }),
            new DataTableColumnModel({
                fieldName: nameof<PromoCodeModel>("startDateTime"),
                displayName: "Start"
            }),
            new DataTableColumnModel({
                fieldName: nameof<PromoCodeModel>("endDateTime"),
                displayName: "End"
            }),
            new DataTableColumnModel({
                fieldName: nameof<PromoCodeModel>("type"),
                displayName: "Type",
                customRenderer: (obj: PromoCodeModel) => <span>{PromoCodeTypeOptions[obj.type].label}</span>
            }),
            new DataTableColumnModel({
                fieldName: nameof<PromoCodeModel>("minPrice"),
                displayName: "Min. Price"
            }),
            new DataTableColumnModel({
                fieldName: nameof<PromoCodeModel>("isActive"),
                displayName: "Is Active",
                customRenderer: (obj: PromoCodeModel) => obj.isActive ? <i className='fa fa-check active' /> : undefined
            }),
            new DataTableColumnModel({
                fieldName: nameof<PromoCodeModel>("isApplicableWithDiscount"),
                displayName: "Is Appl. W/ Discount",
                customRenderer: (obj: PromoCodeModel) => obj.isApplicableWithDiscount ? <i className='fa fa-check active' /> : undefined
            }),
            new DataTableColumnModel({
                fieldName: nameof<PromoCodeModel>("isPublished"),
                displayName: "Is Published",
                customRenderer: (obj: PromoCodeModel) => obj.isPublished ? <i className='fa fa-check active' /> : undefined
            }),
            new DataTableColumnModel({
                fieldName: "",
                displayName: "",
                customRenderer: (obj: PromoCodeModel) => <Link to={urls.promoCodeForm(obj.id)}><i className='fa fa-pencil' /></Link>
            })
        ]
        return new DataTableModel({
            name: "Promo Codes",
            columns,
            customActions: [
                new DataTableModelCustomAction(
                    () => { },
                    () => (
                        <Link to={`${urls.promoCodes}/add`}>
                            <i className="fa fa-plus" />
                        </Link>
                    ),
                ),
            ],
        })
    }
    const dataTableModel = React.useMemo(() => initTable(), [])
    const onRefresh = () => {
        requestData()
    }
    const onPageChange = (page: number) => {
        setPage(page)
        requestData()
    }
    return <DataTable
        isFullScreen
        sortType="ASC"
        sortField="name"
        data={promoCodes.entities}
        pagingInfo={promoCodes.pagingInfo}
        onPageChange={onPageChange}
        dataTable={dataTableModel}
        onRefreshData={onRefresh}
    />
}
export default PromoCodes