import { CallHistoryMethodAction, push } from "connected-react-router"
import { Action, Reducer } from "redux"
import { AppThunkAction } from "."
import { MetaDataModelTourCategory } from "../models/MetaDataModel"
import { TourCategoryModel } from "../models/TourCategoryModel"
import TourCategoriesApiService from "../services/TourCategoriesApiService"
import { urls } from "../urls"

export interface State {
    manageList: TourCategoryModel[]
    edit?: TourCategoryModel
}
const TOURCATEGORY_EDIT = 'A_TOURCATEGORY_EDIT'
interface TourCategoryEdit {
    type: 'A_TOURCATEGORY_EDIT'
    payload?: TourCategoryModel
}
const TOURCATEGORY_UPDATED = 'A_TOURCATEGORY_UPDATED'
interface TourCategoryUpdated {
    type: 'A_TOURCATEGORY_UPDATED'
    payload: TourCategoryModel
}
const TOURSCATEGORIES_MANAGE_RESPONSE = 'A_TOURSCATEGORIES_MANAGE_RESPONSE'
interface ToursCategoriesManageResponse {
    type: 'A_TOURSCATEGORIES_MANAGE_RESPONSE'
    payload: TourCategoryModel[]
}
const TOURCATEGORY_TRANSLATION_SAVE = 'A_TOURCATEGORY_TRANSLATION_SAVE'
interface TourCategoryTranslationSave {
    type: 'A_TOURCATEGORY_TRANSLATION_SAVE'
    payload: MetaDataModelTourCategory
}
type KnownAction = TourCategoryEdit
    | TourCategoryUpdated
    | ToursCategoriesManageResponse
    | TourCategoryTranslationSave
    | CallHistoryMethodAction

export const actionCreators = {
    edit: (tourCategory?: TourCategoryModel) => ({
        type: TOURCATEGORY_EDIT,
        payload: tourCategory,
    }),
    saveTranslation: (countryId: number, metaData: MetaDataModelTourCategory): AppThunkAction<KnownAction> => async (
        dispatch,
    ) => {
        dispatch({ type: TOURCATEGORY_TRANSLATION_SAVE, payload: metaData } as TourCategoryTranslationSave)
    },
    saveData: (tc: TourCategoryModel): AppThunkAction<KnownAction> => async (dispatch) => {
        let fetchTask = await TourCategoriesApiService.Save(tc, dispatch)
        if (fetchTask.IsOk && fetchTask.Content) {
            dispatch({
                type: TOURCATEGORY_UPDATED,
                payload: fetchTask.Content,
            })
            dispatch(push(urls.toursCategoryForm(fetchTask.Content.id)))
        }
    },
    requestManageList: (): AppThunkAction<KnownAction> => async (dispatch) => {
        let fetchTask = await TourCategoriesApiService.getManageList(dispatch)
        if (fetchTask.IsOk && fetchTask.Content) {
            dispatch({
                type: TOURSCATEGORIES_MANAGE_RESPONSE,
                payload: fetchTask.Content,
            })
        }
    },
}

const unloadedState: State = {
    manageList: [] as TourCategoryModel[],
    edit: undefined as TourCategoryModel | undefined,
}

export const reducer: Reducer<State> = (state: State = unloadedState, incomingAction: Action) => {
    const action = incomingAction as KnownAction
    switch (action.type) {
        case TOURCATEGORY_EDIT:
            return {
                ...state,
                edit: action.payload,
            }

        case TOURCATEGORY_TRANSLATION_SAVE:
            if (state.edit) {
                const metaData = state.edit.metaData.some((x) => x.languageId == action.payload.languageId)
                    ? state.edit.metaData.map((x) => (x.languageId == action.payload.languageId ? action.payload : x))
                    : state.edit.metaData.concat([action.payload])
                const edit = Object.assign(TourCategoryModel.Create(state.edit), { metaData })
                return {
                    ...state,
                    edit,
                }
            }
            return state

        case TOURCATEGORY_UPDATED:
            const c = action.payload

            const manageList = state.manageList.some((x) => x.id == c.id)
                ? state.manageList.map((x) => (x.id == c.id ? TourCategoryModel.Create(c) : x))
                : state.manageList.concat([TourCategoryModel.Create(c)])
            return {
                ...state,
                manageList,
            }

        case TOURSCATEGORIES_MANAGE_RESPONSE:
            return {
                ...state,
                manageList: action.payload,
            }
    }
    return state || unloadedState
}

