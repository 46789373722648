import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { DataTable, DataTableColumnModel, DataTableModel, DataTableModelCustomAction, FilterType } from 'traveldesk-ui'
import { TourCategoryModel } from '../models/TourCategoryModel'
import TourCategoriesApiService from '../services/TourCategoriesApiService'
import { ApplicationState } from '../store'
import { actionCreators as toursCategoriesActions } from '../store/ToursCategories'
import { urls } from '../urls'
const ToursCategoriesPage = () => {
    const dispatch = useDispatch()
    const toursCategories = useSelector((state: ApplicationState) => state.toursCategories.manageList)
    const Localization = useSelector((state: ApplicationState) => state.localization.locale)
    React.useEffect(() => {
        dispatch(toursCategoriesActions.requestManageList())
    }, [])
    const onRefresh = () => {
        dispatch(toursCategoriesActions.requestManageList())
    }
    const initDataTable = () => {
        const columns = [
            new DataTableColumnModel({
                fieldName: "id",
                displayName: "#",
                sortable: true,
                filterType: FilterType.TEXT
            }),
            new DataTableColumnModel({
                fieldName: "name",
                displayName: "Name",
                sortable: true,
                filterType: FilterType.TEXT
            }),
            new DataTableColumnModel({
                fieldName: "slug",
                displayName: "Slug",
                sortable: true,
                filterType: FilterType.TEXT
            }),
            new DataTableColumnModel({
                fieldName: '',
                displayName: '',
                width: 35,
                customRenderer: (obj: TourCategoryModel) => (
                    <Link to={`${urls.toursCategories}/${obj.id}`}>
                        <i className="fa fa-pencil" />
                    </Link>
                ),
            })
        ]
        return new DataTableModel({
            name: Localization.getString("Tours Categories"),
            columns,
            customActions: [
                new DataTableModelCustomAction(
                    () => { },
                    () => (
                        <Link to={`${urls.toursCategories}/0`}>
                            <i className="fa fa-plus" />
                        </Link>
                    ),
                ),
            ],
        })
    }
    const dataTableModel = React.useMemo(() => initDataTable(), [])
    return <DataTable
        isFullScreen
        sortType='ASC'
        sortField='name'
        onRefreshData={onRefresh}
        data={toursCategories}
        dataTable={dataTableModel}
    />
}
export default ToursCategoriesPage